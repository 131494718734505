import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { isEqual, orderBy } from 'lodash-es';
import { apiBondsHelper, makeBondGelemSources } from 'services/bonds-helper';
import { AppState } from '../index';

const selectState = (state: AppState) => state.reportDeltaShekel;

const selectFilters = createSelector(selectState, (state) => {
  return state.filters;
});
const selectGroupByRating = createSelector(selectState, ({ isGroupByRating }) => {
  return isGroupByRating;
});

const selectData = createSelector(selectState, (state) => {
  return state.data;
});
const selectOrder = createSelector(selectState, (state) => {
  return state.orderBy;
});

const selectSources = createSelector(selectData, (rows) => {
  return makeBondGelemSources(rows);
});

const selectDataFiltered = createSelector(selectData, selectFilters, (rows, filters) => {
  return rows.filter((row) => {
    const res: Array<boolean> = [];

    const handleArrayIncludes = (arr: string[], rowValue: any) => {
      if (arr.length > 0) {
        const includes = arr.includes(String(rowValue));
        res.push(includes);
      }
    };

    handleArrayIncludes(filters.bonds, row.symbol);
    handleArrayIncludes(filters.issuers, row.issuerName);
    handleArrayIncludes(filters.equityTypeNames, row.equityTypeName);
    handleArrayIncludes(filters.bondMidroogRanks, row.bondMidroogRank);
    handleArrayIncludes(filters.bondMidroogOutlooks, row.bondMidroogOutlook);
    handleArrayIncludes(filters.bondMaalotRanks, row.bondMaalotRank);
    handleArrayIncludes(filters.bondMaalotOutlooks, row.bondMaalotOutlook);

    return res.every(Boolean);
  });
});

const selectDataSorted = createSelector(selectDataFiltered, selectOrder, (rows, order) => {
  if (!order.order) {
    return rows;
  }

  return orderBy(rows, [order.field], [order.order]);
});

const selectDataSelected = createSelector(selectDataFiltered, (rows) => {
  return rows.filter((row) => {
    return row.__isSelected;
  });
});
const selectSelectedSymbols = createSelector(selectDataSelected, (rows) => {
  return rows.map(({ symbol }) => symbol);
});

const selectedSymbols = createSelector(selectState, ({ symbols }) => {
  return symbols;
});

export const selectIsApplyAble = createSelector(
  selectSelectedSymbols,
  selectedSymbols,
  (selectedSymbols, symbols) => {
    if (symbols.length === 0) return false;

    const res = isEqual(selectedSymbols, symbols);

    return !res;
  },
);

const selectAnalyticsArgs = createSelector(selectedSymbols, selectFilters, (symbols, filters) => {
  return { symbols: symbols, date: filters.date };
});
const selectAnalyticsStatsArgs = createSelector(
  selectAnalyticsArgs,
  selectGroupByRating,
  (args, isGrouped) => {
    return { ...args, ratingGroup: isGrouped };
  },
);
const selectStatuses = createSelector(selectState, ({ isLoading }) => {
  return { isLoading: isLoading };
});

const selectAnalyticsLoading = createSelector(
  (state: AppState) => state,
  selectAnalyticsArgs,
  selectAnalyticsStatsArgs,
  (state, args, statArgs) => {
    const items = [
      apiBondsHelper.endpoints.getBondGelemReportDeltaShekel.select(args)(state),
      apiBondsHelper.endpoints.getBondGelemReportStatsDeltaShekel.select(statArgs)(state),
    ];

    return items.some(({ status }) => status === QueryStatus.pending);
  },
);

export const selectorsReportDeltaShekel = {
  filters: selectFilters,
  orderBy: selectOrder,
  sources: selectSources,
  data: selectData,
  dataSelected: selectDataSelected,
  dataFiltered: selectDataSorted,
  loadingAnalytics: selectAnalyticsLoading,
  statuses: selectStatuses,
  chartArgs: selectAnalyticsArgs,
  statsArgs: selectAnalyticsStatsArgs,
  isGrouped: selectGroupByRating,
  isApplyAble: selectIsApplyAble,
};
