import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MaterialThemeProvider,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import React from 'react';
import { AppThemeOptions } from 'styles/theme';

const inputsTheme: ThemeOptions = {
  typography: {
    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '1.1rem',
      lineHeight: 1.43,
    },
  },
};
const theme = createTheme(deepmerge(AppThemeOptions, inputsTheme));

interface Props {
  children: React.ReactNode;
}
export const ProviderInputsTheme: React.FC<Props> = ({ children }) => {
  return <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>;
};
