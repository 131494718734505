import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import CachedIcon from '@mui/icons-material/Cached';
import style from './index.module.scss';
import clsx from 'clsx';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}
export const ButtonFixed: React.FC<Props> = ({ isOpen, onClick }) => {
  const { tp } = useTranslate();

  return (
    <div className={clsx(style.root, isOpen && style.rootVisible)}>
      <Tooltip title={tp('etl-open-tooltip')}>
        <Button className={clsx(style.button)} variant={'contained'} onClick={onClick}>
          <CachedIcon />
        </Button>
      </Tooltip>
    </div>
  );
};
