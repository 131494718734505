import React from 'react';
import style from './index.module.scss';

export const PageLoader: React.FC = () => {
  return (
    <div className={style.root}>
      <div className={style.scene}>
        <div className={style.shadow} />
        <div className={style.jumper}>
          <div className={style.spinner}>
            <div className={style.scaler}>
              <div className={style.loader}>
                <div className={style.cuboid}>
                  <div className={style.cuboid__side} />
                  <div className={style.cuboid__side} />
                  <div className={style.cuboid__side} />
                  <div className={style.cuboid__side} />
                  <div className={style.cuboid__side} />
                  <div className={style.cuboid__side} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
