import { base64ToFileStream, isFileLike, ValueFileUploaderFile } from 'utils/file-uploader';
import { omit } from 'utils/other';
import { API, makePatchArgs } from 'utils/service';
import { ArrayType, isSettledFulfilled, isSettledRejected, PromiseType } from 'utils/types';
import { ICloudFileViewModel } from '__generated__/api';
import { DynamicParams, DynamicResult } from './models';
import { AxiosResponse } from 'axios';

type FileConfig = ICloudFileViewModel | ((file: ValueFileUploaderFile) => ICloudFileViewModel);

interface RequestUploadFileInput<T> {
  key: keyof T;
  file: ValueFileUploaderFile;
  config: FileConfig | undefined;
}
const requestUploadFile = async <T>({ key, file, config }: RequestUploadFileInput<T>) => {
  let configObject = typeof config === 'function' ? config(file) : config;

  const { data } = await API.api.mediaUploadsUploadFileToCloudCreate({
    fileStreamString: base64ToFileStream(file.value),
    fileName: file.name,
    ...configObject,
  });

  return { key, file, data };
};

type PostFieldsConfig<T> = {
  [K in keyof T]?: FileConfig;
};
export const postFiles = async <T extends Record<string, any>>(
  formData: T,
  fields: PostFieldsConfig<T>,
) => {
  const fileFields = Object.keys(fields);

  let entriesToUpload: [keyof T, ValueFileUploaderFile][] = [];

  fileFields.forEach((key) => {
    const value = formData[key];
    if (isFileLike(value)) {
      entriesToUpload.push([key, value]);
    }
  });

  // primary fields;
  const primary = omit(formData, ...entriesToUpload.map(([key]) => key));

  // upload files to the server
  const result = await Promise.allSettled(
    entriesToUpload.map(async ([key, file]) => {
      let config = fields[key];
      return requestUploadFile({ key, file, config });
    }),
  );

  // successfully uploaded files
  const fulfilled = result.filter(isSettledFulfilled);

  // errors
  const rejected = result.filter(isSettledRejected);

  // Callback to remove all successfully uploaded files
  const transaction = async () => {
    await Promise.allSettled(
      fulfilled.map((res) => {
        return API.api.mediaUploadsRemoveFileFromCloudUpdate(res.value.data as any);
      }),
    );
  };

  // Check any errors
  if (rejected.length) {
    // remove all successfully uploaded files
    await transaction();
    throw new Error(rejected[0].reason);
  }

  // make a result
  const updatedFields = Object.fromEntries(
    fulfilled.map(({ value }) => [value.key, value.data.filePath]),
  );

  return [{ ...primary, ...updatedFields } as T, transaction] as const;
};

type PatchFieldsConfig<T> = {
  [K in keyof T]?: FileConfig;
};
export const patchFiles = async <T>(
  formData: T,
  formDataOld: T | null | undefined,
  fields: PatchFieldsConfig<T>,
) => {
  const fileFields = Object.keys(fields) as Array<keyof T>;

  let entriesToUpload: [keyof T, ValueFileUploaderFile][] = [];
  let entriesToDelete: [keyof T, string][] = [];

  fileFields.forEach((key) => {
    const newItem = formData[key];
    const oldItem = formDataOld && formDataOld[key];

    if (isFileLike(newItem)) {
      entriesToUpload.push([key, newItem]);
    }
    if (newItem !== undefined && oldItem && typeof oldItem === 'string' && oldItem !== newItem) {
      entriesToDelete.push([key, oldItem]);
    }
  });

  // remove files from the server
  const deletedResult = await Promise.all(
    entriesToDelete.map(async ([key, value]) => {
      try {
        await API.api.mediaUploadsRemoveFileFromCloudUpdate({ filePath: value });
      } catch (e) {
        console.warn(`PatchFiles: ${value}`);
      }

      return { key, data: '' };
    }),
  );

  // upload files to the server
  const uploadedResult = await Promise.all(
    entriesToUpload.map(async ([key, file]) => {
      let config = fields[key];
      return requestUploadFile({ key, file, config });
    }),
  );

  // make a result
  const formDataResult = {
    ...formData,
    ...Object.fromEntries(deletedResult.map(({ key, data }) => [key, data])),
    ...Object.fromEntries(uploadedResult.map(({ key, data }) => [key, data.filePath])),
  };

  return [formDataResult as T] as const;
};

type RequestRank<T = any> = (
  query: DynamicParams,
  ...rest: any[]
) => Promise<AxiosResponse<DynamicResult<T>>>;

export const makeGetNextRank = <
  R extends RequestRank,
  K extends keyof ArrayType<PromiseType<ReturnType<R>>['data']['items']>,
>(
  request: R,
  options: { field: K },
) => {
  const { field } = options;
  return async (...query: Parameters<R>) => {
    const [firstArg, ...restArgs] = query;
    const result = await request(
      {
        ...firstArg,
        Take: 1,
        Select: String(field),
        OrderBy: `${String(field)} desc `,
      },
      ...restArgs,
    );
    const item = result.data.items[0];

    const value = (item ? item[field] : 0) || 0;

    return { [field]: Number(value) + 1 } as Record<K, number>;
  };
};

interface MoveRowsOptions<T extends Record<string, any>> {
  mainField: keyof T;
  moveField: keyof T;
}
interface MoveRowsData<T extends Record<string, any>> {
  newRows: Partial<T>[];
  oldRows: Partial<T>[];
}

type RequestReorder = (...rest: any[]) => Promise<any>;
export const makeReorderRows = <T extends Record<string, any>>(
  request: RequestReorder,
  options: MoveRowsOptions<T>,
) => {
  return (data: MoveRowsData<T>) => {
    const { newRows, oldRows } = data;

    return Promise.all(
      newRows.map((oldRecord, i) => {
        const patchData = {
          [options.mainField]: oldRecord[options.mainField],
          [options.moveField]: oldRows[i][options.moveField],
        };
        return request(...makePatchArgs(patchData));
      }),
    );
  };
};
