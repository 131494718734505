import { replaceSpace } from 'utils/other';
import * as yup from 'yup';
import { ILabel, ILabelDynamicResponse } from '__generated__/api';
import { DynamicOrder } from '../../utils/dynamic';

export const schemaLabels = yup.object({
  title: yup.string().required('rule-required').default(''),
  labelKey: yup.string().required('rule-required').transform(replaceSpace).default(''),
  languageID: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().required('rule-required').default(true),
});

export interface IGridLabel extends Pick<ILabel, 'id' | 'title' | 'labelKey' | 'isActive'> {
  languageTitle: string;
}

export interface IGridLabelResponse extends Omit<ILabelDynamicResponse, 'items'> {
  items: IGridLabel[];
}

export enum LABEL_SOURCE_ID {
  APP = 'f4415e64-3d79-451d-9a95-2d91053b6f9d',
}

export interface IGridLabelParams {
  languageID: string;
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  labelSourceID: string;
  isActive: boolean | null;
}
