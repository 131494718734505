import { createSelector } from '@reduxjs/toolkit';
import { isEqual, keyBy } from 'lodash-es';
import { filtersSomeIDs } from 'utils/front-filters';
import { AppState } from '../index';
import { initState } from './slice';

const selectState = (state: AppState) => state.profileBonds;

export const selectProfileBondsInit = createSelector(selectState, ({ isInit }) => {
  return isInit;
});

export const selectProfileBondsCustomState = createSelector(selectState, ({ custom }) => {
  return custom;
});
export const selectProfileBondsCustomFilters = createSelector(
  selectProfileBondsCustomState,
  ({ filters }) => {
    return filters;
  },
);
export const selectProfileBondsCustomHasFilters = createSelector(
  selectProfileBondsCustomFilters,
  (filters) => {
    return !isEqual(filters, initState().custom.filters);
  },
);

const selectProfileBondsCustomData_ = createSelector(selectProfileBondsCustomState, ({ data }) => {
  return data;
});
export const selectProfileBondsCustomDataFiltered = createSelector(
  selectProfileBondsCustomData_,
  selectProfileBondsCustomFilters,
  (data, filters) => {
    return data.filter((row) => {
      return [
        filtersSomeIDs(filters.bonds, row.symbol),
        filtersSomeIDs(filters.issuerIDs, row.issuerID),
      ].every(Boolean);
    });
  },
);
export const selectProfileBondsCustomDataSelected = createSelector(
  selectProfileBondsCustomDataFiltered,
  (data) => {
    return data.filter((item) => item.__isSelected);
  },
);

export const selectProfileBondsCustomDataAnySelected = createSelector(
  selectProfileBondsCustomDataSelected,
  (selected) => {
    return selected.length > 0;
  },
);
export const selectProfileBondsCustomDataAllSelected = createSelector(
  selectProfileBondsCustomDataFiltered,
  selectProfileBondsCustomDataSelected,
  selectProfileBondsCustomDataAnySelected,
  (all, selected, anySelected) => {
    return anySelected && all.length === selected.length;
  },
);

const selectProfileBondsCustomMap = createSelector(selectProfileBondsCustomData_, (data) => {
  return keyBy(data, 'bondID');
});

export const selectProfileBondsSystemState = createSelector(selectState, ({ system }) => {
  return system;
});
export const selectProfileBondsSystemFilters = createSelector(
  selectProfileBondsSystemState,
  ({ filters }) => {
    return filters;
  },
);
export const selectProfileBondsSystemHasFilters = createSelector(
  selectProfileBondsSystemFilters,
  (filters) => {
    return !isEqual(filters, initState().system.filters);
  },
);
const selectProfileBondsSystemData = createSelector(
  selectProfileBondsSystemState,
  selectProfileBondsCustomMap,
  ({ data }, map) => {
    return data.map((item) => ({
      ...item,
      __isInCustom: !!map[String(item.id)],
    }));
  },
);
export const selectProfileBondsSystemDataFiltered = createSelector(
  selectProfileBondsSystemData,
  selectProfileBondsSystemFilters,
  (data, filters) => {
    return data.filter((row) => {
      return [
        filtersSomeIDs(filters.bonds, row.symbol),
        filtersSomeIDs(filters.issuerIDs, row.issuerID),
        filtersSomeIDs(filters.equityTypeIDs, row.equityTypeID),
        filtersSomeIDs(filters.branchIDs, row.branchID),
        filtersSomeIDs(filters.linkedAssetIDs, row.linkedAssetID),
        filtersSomeIDs(filters.maalotRatingIDs, row.maalotRatingID),
        filtersSomeIDs(filters.midroogRatingIDs, row.midroogRatingID),
      ].every(Boolean);
    });
  },
);

export const selectProfileBondsSystemDataSelected = createSelector(
  selectProfileBondsSystemDataFiltered,
  (data) => {
    return data.filter((item) => item.__isSelected);
  },
);

export const selectProfileBondsSystemDataAnySelected = createSelector(
  selectProfileBondsSystemDataSelected,
  (selected) => {
    return selected.length > 0;
  },
);
export const selectProfileBondsSystemDataAllSelected = createSelector(
  selectProfileBondsSystemDataFiltered,
  selectProfileBondsSystemDataSelected,
  selectProfileBondsSystemDataAnySelected,
  (all, selected, anySelected) => {
    return anySelected && all.length === selected.length;
  },
);

export const selectProfileBondsLoading = createSelector(
  selectProfileBondsSystemState,
  selectProfileBondsCustomState,
  (system, custom) => {
    return system.isLoading || custom.isLoading;
  },
);
