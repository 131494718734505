import { useAppSelector } from 'hooks/redux';
import { selectAccountUser } from 'store/auth';

export const useCurrentUser = () => {
  const user = useAppSelector(selectAccountUser);

  if (!user) {
    throw new Error('useCurrentUser: use private only');
  }

  return user;
};
