import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { ILanguage, ILanguageDirection, ILanguageDynamicResponse } from '__generated__/api';
export const schemaLanguages = yup.object({
  title: yup.string().required('rule-required').default(''),
  culture: yup.string().required('rule-required').default(''),
  direction: yup.number().required('rule-required').default(ILanguageDirection.LTR),
  icon: yup.mixed().default(''),
  isActive: yup.boolean().required('rule-required').default(true),
});

export interface IGridLanguage
  extends Pick<
    ILanguage,
    'id' | 'rank' | 'icon' | 'title' | 'culture' | 'direction' | 'isActive'
  > {}

export interface IGridLanguageResponse extends Omit<ILanguageDynamicResponse, 'items'> {
  items: IGridLanguage[];
}

export interface IGridLanguageArg {
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  isActive: boolean | null;
}
