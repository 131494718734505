import { AppLoading } from 'components/app-loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { memo, useEffect, useState } from 'react';
import { apiLabels } from 'services/labels';
import { apiLanguages } from 'services/languages';
import { actionAccountSetLanguageID, selectAccountLanguageID } from 'store/auth';

interface Props {
  children: React.ReactNode;
}

export const TranslateProvider = memo<Props>(({ children }) => {
  const [isInitiated, setIsInitiated] = useState(false);
  const dispatch = useAppDispatch();
  const storeLanguageID = useAppSelector(selectAccountLanguageID);
  const [triggerGetLanguages, stateLanguages] = apiLanguages.useLazyGetAllLanguagesQuery();
  const [triggerGetDefault, stateDefault] = apiLanguages.useLazyGetDefaultLanguageIDQuery();

  const { isSuccess: isSuccessLabels, isFetching } = apiLabels.useGetAppLabelsQuery(
    { languageID: storeLanguageID || '' },
    { skip: !storeLanguageID },
  );

  // critical error can not continue
  if (stateLanguages.error) {
    throw stateLanguages.error;
  }

  // critical error can not continue
  if (stateDefault.error) {
    throw stateDefault.error;
  }

  useEffect(() => {
    let currentStream = true;

    const setDefaultLanguageID = async () => {
      if (storeLanguageID) {
        const resultLanguages = await triggerGetLanguages(undefined, true);

        if (!currentStream) return;

        if (!resultLanguages.data) {
          return dispatch(actionAccountSetLanguageID(null));
        }

        const language = resultLanguages.data.find(
          (lang) => lang.id === storeLanguageID && lang.isActive,
        );

        // language is already set
        if (language) {
          setIsInitiated(true);
          return;
        }
      }

      const resultDefault = await triggerGetDefault(undefined, true);

      if (!currentStream) return;

      if (!resultDefault.data) {
        return;
      }

      dispatch(actionAccountSetLanguageID(resultDefault.data));

      setIsInitiated(true);
    };

    setDefaultLanguageID();

    return () => {
      currentStream = false;
    };
  }, [storeLanguageID, triggerGetLanguages, triggerGetDefault, dispatch]);

  const isReady = isInitiated && isSuccessLabels;

  return (
    <>
      {isReady && children}
      {(!isReady || isFetching) && <AppLoading />}
    </>
  );
});
