import { call, delay, put, race, take } from 'typed-redux-saga';
import { actionTradingEtlEndPolling, actionTradingEtlStartPolling } from './slice';
import { actionTradingEtlFetchData } from './actions';

const POLLING_DELAY = 60_000;

function* polling() {
  while (true) {
    yield* put(actionTradingEtlFetchData());

    yield* race([
      take(actionTradingEtlFetchData.fulfilled),
      take(actionTradingEtlFetchData.rejected),
    ]);

    yield* delay(POLLING_DELAY);
  }
}
function* sagaWatchPolling() {
  while (true) {
    yield* take(actionTradingEtlStartPolling);
    yield* race([call(polling), take(actionTradingEtlEndPolling)]);
  }
}

export const sagasTradingEtl = [sagaWatchPolling()];
