import { Box, Theme, useMediaQuery } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Panel } from './components';
import style from './index.module.scss';
import { TradingEtlTag } from 'components/trading-etl-tag';

export const PrivateLayout = memo(() => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));
  return (
    <>
      <TradingEtlTag />
      <Box className={style.root}>
        {!isMobile && (
          <Box className={style.panel}>
            <Panel />
          </Box>
        )}
        <Box className={style.content}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
});
