import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from '__generated__/api';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
} from 'axios';
import type { DynamicParams, DynamicResult } from 'modules/dynamic-service';
import { Kill, ParamsExceptFirst } from 'utils/types';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}?((\.\d+)|$)/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const [_dateString, _ms] = value.split('.');
        const _dateNumber = Date.parse(_dateString);

        if (_dateNumber) {
          const ms = _ms || '000';
          // ms has no timezone | only numbers
          if (/^[0-9]+$/gi.test(ms)) {
            return `${_dateString}.${ms}Z`;
          }
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const API = new Api();
export const apiApp = API.instance;
apiApp.defaults.transformResponse = [axiosDateTransformer];

export const APIFree = new Api();
export const apiFree = APIFree.instance;
apiFree.defaults.transformResponse = [axiosDateTransformer];

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> => async (config) => {
    try {
      const result = await apiApp({
        method: 'get',
        ...config,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };

export const normalizeForm = <T extends Record<string, any>>(data: T): Kill<T> => {
  return Object.keys(data).reduce(
    (acc, key) => {
      if (acc[key] === null) {
        // @ts-ignore
        acc[key] = '';
      }
      return acc;
    },
    { ...data },
  );
};
export enum RTK_TAGS {
  USER_PERMISSIONS = 'USER_PERMISSIONS',
  USERS = 'USERS',
  USERS_BONDS = 'USERS_BONDS',
  LABELS = 'LABELS',
  LANGUAGES = 'LANGUAGES',
  STOCK_MARKET_EXCLUDED_DATE = 'STOCK_MARKET_EXCLUDED_DATE',
  ISSUERS = 'ISSUERS',
  BOND_DEFAULT_ALERTS = 'BOND_DEFAULT_ALERTS',
  STOCK_DEFAULT_ALERTS = 'STOCK_DEFAULT_ALERTS',
  USER_PROFILE_BOND_DEFAULT_ALERTS = 'USER_PROFILE_BOND_DEFAULT_ALERTS',
  USER_PROFILE_STOCK_DEFAULT_ALERTS = 'USER_PROFILE_STOCK_DEFAULT_ALERTS',
  BONDS_HELPERS = 'BONDS_HELPERS',
  STOCKS_HELPERS = 'STOCKS_HELPERS',
  GTO_ENDPOINTS = 'gto-endpoints',
  GTO_HELPER = 'gto-helper',
  MIGRATIONS = 'migrations',
  DATA_ETL = 'data-etl',
  BRANCHES = 'branches',
  EQUITY_TYPES = 'EQUITY_TYPES',
  LINKED_ASSETS = 'LINKED_ASSETS',
  MAALOT_RATINGS = 'MAALOT_RATINGS',
  MIDROOG_RATINGS = 'MIDROOG_RATINGS',
  TRADE_STATUSES = 'TRADE_STATUSES',
}
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(RTK_TAGS),
  endpoints: () => ({}),
});

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error> | any) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const isRejectedMutation = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isFulfilledMutation = <T>(mutationResult: any): mutationResult is { data: T } => {
  return !isRejectedMutation(mutationResult);
};

export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

const prepareFieldWithID = (value: any, key: any) => {
  return value === '' && String(key).endsWith('ID') ? null : value;
};

export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    const value = acc[key];
    acc[key] = prepareFieldWithID(value, key);
    return acc;
  }, data);
};
export const preparePatchRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  return Object.entries(prepareRequestData(data))
    .filter(([, value]) => value !== undefined)
    .map(([path, value]) => {
      return { path, value };
    });
};

export const makePatchArgs = <
  T extends Record<string, any> = { id: string },
  K extends keyof T = 'id',
>(
  data: T,
  field: K = 'id' as K,
) => {
  const { [field]: id, ...rest } = data;
  return [String(id), preparePatchRequestData(rest)] as const;
};

export const decoratorGetArgsAsPost = <C extends (...args: any[]) => any>(callback: C) => {
  return (arg1: DynamicParams, ...rest: ParamsExceptFirst<C>) => {
    return callback(arg1, ...rest) as ReturnType<C>;
  };
};

export const rtkAdapterDynamicToSource = <Result>(
  response: AxiosResponse<DynamicResult<Result>>,
) => {
  return { ...response, data: response.data.items } as AxiosResponse<Result[]>;
};
export const rtkAdapterDynamicItem = <Result>(response: AxiosResponse<DynamicResult<Result>>) => {
  const data = response.data.items[0];

  if (!data) {
    throw new Error('record-not-found');
  }
  return { ...response, data } as AxiosResponse<Result>;
};

export const rtkAdapterDynamicCount = (response: AxiosResponse<DynamicResult<unknown>>) => {
  const count = response.data.count;

  return { ...response, data: count as number } as AxiosResponse<number>;
};

export const rtkAdapterError = (response: AxiosError | unknown) => {
  return { error: response as AxiosError };
};
