import { all, put, take, takeEvery, select } from 'typed-redux-saga';
import { actionProfileBondsCustomFetch, actionProfileBondsSystemFetch } from './actions';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionsProfileBonds } from './slice';
import { apiBondsHelper } from 'services/bonds-helper';
import { selectProfileBondsInit } from './selectors';
import { selectAccountUser } from '../auth';
import { apiUserProfileBondDefaultAlerts } from '../../services/user-profile-bond-default-alerts';
import { apiUserProfileBonds } from '../../services/user-profile-bonds';

function* workerUpdateCustom() {
  const isInit = yield* select(selectProfileBondsInit);

  if (!isInit) return;

  const user = yield* select(selectAccountUser);
  if (!user) return;

  yield* put(actionProfileBondsCustomFetch({ userProfileID: String(user.appUserID) }));
}

function* sagaMakeInit() {
  while (true) {
    yield* all([
      take(actionProfileBondsSystemFetch.pending),
      take(actionProfileBondsCustomFetch.pending),
    ]);

    yield* put(actionsProfileBonds.setInit());
  }
}

export const sagasProfileBonds = [
  sagaMakeInit(),
  takeEvery(actionProfileBondsSystemFetch.rejected, workerErrorNotifyThunk),
  takeEvery(actionProfileBondsCustomFetch.rejected, workerErrorNotifyThunk),
  takeEvery(
    [
      apiUserProfileBonds.endpoints.patchUserProfileBondAlert.matchFulfilled,
      apiUserProfileBondDefaultAlerts.endpoints.patchBondDefaultAlert.matchFulfilled,
      apiBondsHelper.endpoints.updateIsActivePortfolioBonds.matchFulfilled,
      apiBondsHelper.endpoints.bondsResetToUserDefault.matchFulfilled,
      apiBondsHelper.endpoints.bondsResetToSystemDefault.matchFulfilled,

      apiBondsHelper.endpoints.bondsLoadUserBondsAlertsBySymbols.matchFulfilled,
      apiBondsHelper.endpoints.bondsLoadUserBondsAlertsBySymbols.matchRejected,

      apiBondsHelper.endpoints.bondsLoadUserBondsAlertsByIssuers.matchFulfilled,
      apiBondsHelper.endpoints.bondsLoadUserBondsAlertsByIssuers.matchRejected,

      apiBondsHelper.endpoints.bondsDeleteUserBondsAlerts.matchFulfilled,
      apiBondsHelper.endpoints.bondsInsertNewUserBondsAlerts.matchFulfilled,
    ],
    workerUpdateCustom,
  ),
];
