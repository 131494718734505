import { dateFormat } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import { dateMore, decoratorIsNotNullable, equals } from 'utils/dynamic';
import { APP_ERROR_CODES, AppError } from 'utils/errors';
import { API, apiRtk, RTK_TAGS, rtkAdapterDynamicToSource, rtkAdapterError } from 'utils/service';
import { IDataBondStatItem, ILinkAssetType, IQueryType } from '../../__generated__/api';
import {
  BaseBondsGelemReportInput,
  BaseBondsGelemStatsReportInput,
  GridAlertBondOnline1Response,
  GridAlertBondOnline2Response,
  GridAlertBondOnline3Response,
  GridAlertBondOnline4Response,
  GridDashboardDeltaEmailResponse,
  GridDashboardDeltaResponse,
  GridDashboardYieldsEmailResponse,
  GridDashboardYieldsResponse,
  GridDeltaAlerts1Response,
  GridDeltaAlerts2Response,
  GridDeltaAlerts3Response,
  GridDeltaAlerts4Response,
  GridPortfolioSynDiffMooversEmailResponse,
  GridPortfolioSynDiffMooversResponse,
  GridPortfolioYieldBrutoMooversEmailResponse,
  GridPortfolioYieldBrutoMooversResponse,
  GridPortfolioYieldsEmailResponse,
  GridPortfolioYieldsResponse,
  GridYieldAlerts1Response,
  GridYieldAlerts2Response,
  GridYieldAlerts3Response,
  GridYieldAlerts4Response,
  IBondGelemReportDelta,
  IBondGelemReportYield,
  IDailyEmailPortfolioArg,
  IDailyEmailPortfolioYieldDeltaArg,
  IDashboardArg,
  IPortfolioYieldsArg,
  ISystemBondArg,
  ISystemBondOutput,
  UpdateIsActivePortfolioBondsInput,
} from './models';
import { SELECT_BOND_GELEM_REPORT_DELTA, SELECT_BOND_GELEM_REPORT_YIELD } from './queries';

const requestGet = API.api.bondsHelperGetUserBondsDynamicList;
const requestGetBondsOnline = API.api.bondsHelperGetUserBondsOnlineDynamicList;
const requestGetBondStats = API.api.bondsHelperGetUserBondStatsList;

const requestGetPostSystem = API.api.bondsHelperGetSystemBondItemsDynamicCreate;
const requestFill = API.api.bondsHelperFillingMissingRawDateCreate;

export * from './helpers';
export * from './models';
export * from './queries';

const yieldsFilter = ({
  grossYieldToMaturityMin,
  grossYieldToMaturityMax,
  weekAverageNISMax,
  weekAverageNISMin,
  dailyTurnoverMax,
  dailyTurnoverMin,
  grossYieldToMaturityDeltaByDurationMax,
  grossYieldToMaturityDeltaByDurationMin,
  grossDurationMax,
  grossDurationMin,
  bonds,
  midroogRatings,
  lastRateDate,
  institutional,
}: Omit<IPortfolioYieldsArg, 'take' | 'skip' | 'orderBy' | 'UserProfileID'>) =>
  dynamic
    .mergeFilters(
      dynamic.makeFilter(
        'grossYieldToMaturity',
        [grossYieldToMaturityMin, grossYieldToMaturityMax],
        dynamic.minMax,
      ),
      dynamic.makeFilter('weekAverageNIS', [weekAverageNISMin, weekAverageNISMax], dynamic.minMax),
      dynamic.makeFilter('dailyTurnover', [dailyTurnoverMin, dailyTurnoverMax], dynamic.minMax),
      dynamic.makeFilter(
        'grossYieldToMaturityDeltaByDuration',
        [grossYieldToMaturityDeltaByDurationMin, grossYieldToMaturityDeltaByDurationMax],
        dynamic.minMax,
      ),
      dynamic.makeFilter('grossDuration', [grossDurationMin, grossDurationMax], dynamic.minMax),
      dynamic.makeFilter(
        'symbol',
        bonds,
        dynamic.decoratorValueArray(dynamic.decoratorStringify(dynamic.equals)),
      ),
      dynamic.makeFilter(
        'midroogRatingID',
        midroogRatings,
        dynamic.decoratorValueArray(dynamic.equals),
      ),
      dynamic.makeFilter('lastRateDate', lastRateDate, decoratorIsNotNullable(dateMore)),
      dynamic.makeFilter(
        'institutional',
        institutional ? 0 : null,
        decoratorIsNotNullable(dynamic.equals),
      ),
    )
    .join('&&');

export const apiBondsHelper = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridPortfolioYieldsEmail: build.query<
      GridPortfolioYieldsEmailResponse,
      IDailyEmailPortfolioArg
    >({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID }) => {
        try {
          const res = await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                dynamic.equals('institutional', 0),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                '(LastRateDate != null && LastRateDate.Value.Date > DateTime.Today.Date.AddDays(-3)) && (GrossYieldToMaturity != null && GrossYieldToMaturity >= 0.02) && (GrossYieldToMaturityDeltaByDuration != null && GrossYieldToMaturityDeltaByDuration >= 0.02) && (GrossDuration != null && GrossDuration >= 0.5)',
              )
              .join('&&'),
            Select: dynamic.select(
              'lastRateDate',
              'equityTypeName',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
            ),
            UserProfileID,
            Count: true,
          });
          return res;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getUserBondStats: build.query({
      queryFn: async ({ UserProfileID, LinkedAssetID, RatingGroup, Type }) => {
        try {
          return await requestGetBondStats({
            UserProfileID,
            LinkedAssetID,
            RatingGroup,
            Type,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridPortfolioYields: build.query<GridPortfolioYieldsResponse, IPortfolioYieldsArg>({
      queryFn: async ({
        take: Take,
        skip: Skip,
        orderBy,
        UserProfileID,
        additionalFields,
        ...filters
      }) => {
        try {
          const res = await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(yieldsFilter(filters), dynamic.equals('isActive', 1))
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'equityTypeName',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              ...(additionalFields ? [...additionalFields] : []), // Ensure additionalFields is an array
              'issuerID',
              'linkedAssetID',
              'equityTypeID',
            ),
            UserProfileID,
            Count: true,
          });
          return res;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridPortfolioYieldBrutoMooversEmail: build.query<
      GridPortfolioYieldBrutoMooversEmailResponse,
      IDailyEmailPortfolioArg
    >({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID }) => {
        try {
          const res = await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                dynamic.equals('institutional', 0),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                '(LastRateDate != null && LastRateDate.Value.Date > DateTime.Today.Date.AddDays(-3)) && (GrossYieldToMaturity != null && GrossYieldToMaturity >= 0.02) && (GrossYieldToMaturityDeltaByDuration != null && GrossYieldToMaturityDeltaByDuration >= 0.02) && (GrossDuration != null && GrossDuration >= 0.5)',
              )
              .join('&&'),
            Select: dynamic.select(
              'lastRateDate',
              'equityTypeName',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'viewYieldBrutoLastDay',
              'calcYieldBrutoMoovers',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
            ),
            UserProfileID,
            Count: true,
          });
          return res;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridPortfolioYieldBrutoMoovers: build.query<
      GridPortfolioYieldBrutoMooversResponse,
      IPortfolioYieldsArg
    >({
      queryFn: async ({
        take: Take,
        skip: Skip,
        orderBy,
        UserProfileID,
        additionalFields,
        ...filters
      }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(yieldsFilter(filters), dynamic.equals('isActive', 1))
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'viewYieldBrutoLastDay',
              'calcYieldBrutoMoovers',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              ...(additionalFields ? [...additionalFields] : []), // Ensure additionalFields is an array
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
              'equityTypeID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridPortfolioSynDiffMoovers: build.query<
      GridPortfolioSynDiffMooversResponse,
      IPortfolioYieldsArg
    >({
      queryFn: async ({
        take: Take,
        skip: Skip,
        orderBy,
        UserProfileID,
        additionalFields,
        ...filters
      }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(yieldsFilter(filters), dynamic.equals('isActive', 1))
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'issuerName',
              'name',
              'grossYieldToMaturityDeltaByDuration',
              'viewSynDiffLastDay',
              'calcSynDiffMoovers',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              ...(additionalFields ? [...additionalFields] : []),
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
              'equityTypeID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridPortfolioSynDiffMooversEmail: build.query<
      GridPortfolioSynDiffMooversEmailResponse,
      IDailyEmailPortfolioArg
    >({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                dynamic.equals('institutional', 0),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                '(LastRateDate != null && LastRateDate.Value.Date > DateTime.Today.Date.AddDays(-3)) && (GrossYieldToMaturity != null && GrossYieldToMaturity >= 0.02) && (GrossYieldToMaturityDeltaByDuration != null && GrossYieldToMaturityDeltaByDuration >= 0.02) && (GrossDuration != null && GrossDuration >= 0.5)',
              )
              .join('&&'),
            Select: dynamic.select(
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'viewSynDiffLastDay',
              'calcSynDiffMoovers',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDeltaAlerts1: build.query<GridDeltaAlerts1Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert1/100', 'calcSynDiffAvgWeekMove'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'calcSynDiffAvgWeek',
              'calcSynDiffAvgWeekMove',
              'alert1',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDeltaAlerts2: build.query<GridDeltaAlerts2Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert2/100', 'calcSynDiffAvgMonthMove'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'calcSynDiffAvgMonth',
              'calcSynDiffAvgMonthMove',
              'alert2',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDeltaAlerts3: build.query<GridDeltaAlerts3Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert3/100', 'calcSynDiffAvgYearMove'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'calcSynDiffAvgYear',
              'calcSynDiffAvgYearMove',
              'alert3',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDeltaAlerts4: build.query<GridDeltaAlerts4Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert4', 'grossYieldToMaturityDeltaByDuration'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'calcSynDiffAvgYear',
              'calcSynDiffSDYear',
              'alert4',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridYieldAlerts1: build.query<GridYieldAlerts1Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert5/100', 'calcYieldBrutoAvgWeekMove'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'viewYieldBrutoMinusLinkedAssetCurrent',
              'viewYieldBrutoMinusLinkedAssetAvgWeek',
              'viewYieldBrutoAvgWeek',
              'alert5',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridYieldAlerts2: build.query<GridYieldAlerts2Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert6/100', 'calcYieldBrutoAvgMonthMove'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'viewYieldBrutoMinusLinkedAssetCurrent',
              'viewYieldBrutoMinusLinkedAssetAvgMonth',
              'viewYieldBrutoAvgMonth',
              'alert6',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridYieldAlerts3: build.query<GridYieldAlerts3Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert7/100', 'calcYieldBrutoAvgYearMove'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'viewYieldBrutoMinusLinkedAssetCurrent',
              'viewYieldBrutoMinusLinkedAssetAvgYear',
              'viewYieldBrutoAvgYear',
              'alert7',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridYieldAlerts4: build.query<GridYieldAlerts4Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.less('alert8', 'grossYieldToMaturity'),
                dynamic.equals('isActive', 1),
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'calcYieldBrutoAvgYear',
              'calcYieldBrutoSDYear',
              'alert8',
              'linkedAssetName',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
              'issuerID',
              'linkedAssetID',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDashboardYields: build.query<GridDashboardYieldsResponse, IDashboardArg>({
      queryFn: async ({
        take: Take,
        skip: Skip,
        orderBy,
        UserProfileID,
        Filter,
        RatingGroup,
        ...filters
      }) => {
        try {
          const res = await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                dynamic.notEquals('grossYieldToMaturityDeltaByDuration', null),
                dynamic.more('grossYieldToMaturityDeltaByDuration', 0),
                dynamic.lessOrEquals('viewMidroogRatingRank', 20),
                Filter,
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'equityTypeName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'grossDuration',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'ratingGroup',
            ),
            UserProfileID,
            Count: true,
          });
          return res as unknown as any;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDashboardYieldsEmail: build.query<
      GridDashboardYieldsEmailResponse,
      IDailyEmailPortfolioYieldDeltaArg
    >({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, Filter }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                dynamic.equals('institutional', 0),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                '(LastRateDate != null && LastRateDate.Value.Date > DateTime.Today.Date.AddDays(-3)) && (GrossYieldToMaturity != null && GrossYieldToMaturity >= 0.02) && (GrossYieldToMaturityDeltaByDuration != null && GrossYieldToMaturityDeltaByDuration >= 0.02) && (GrossDuration != null && GrossDuration >= 0.5)',
                Filter,
              )
              .join('&&'),
            Select: dynamic.select(
              'symbol',
              'equityTypeName',
              'issuerName',
              'name',
              'grossYieldToMaturity',
              'grossDuration',
              'bondMidroogRank',
              'bondMidroogOutlook',
            ),
            UserProfileID,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDashboardDelta: build.query<GridDashboardDeltaResponse, IDashboardArg>({
      queryFn: async ({
        take: Take,
        skip: Skip,
        orderBy,
        UserProfileID,
        Filter,
        RatingGroup,
        ...filters
      }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                yieldsFilter(filters),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                dynamic.notEquals('grossYieldToMaturityDeltaByDuration', null),
                dynamic.more('grossYieldToMaturityDeltaByDuration', 0),
                dynamic.lessOrEquals('viewMidroogRatingRank', 20),
                Filter,
              )
              .join('&&'),
            Select: dynamic.select(
              'bondID',
              'symbol',
              'equityTypeName',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'grossDuration',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'ratingGroup',
              'ratingGroupRank',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridDashboardDeltaEmail: build.query<
      GridDashboardDeltaEmailResponse,
      IDailyEmailPortfolioYieldDeltaArg
    >({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, Filter }) => {
        try {
          return await requestGet({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic
              .mergeFilters(
                dynamic.equals('institutional', 0),
                dynamic.equals('isActive', 1),
                dynamic.notEquals('equityTypeID', '349870b6-b60e-4429-97aa-e755ca16cc6a'),
                '(LastRateDate != null && LastRateDate.Value.Date > DateTime.Today.Date.AddDays(-3)) && (GrossYieldToMaturity != null && GrossYieldToMaturity >= 0.02) && (GrossYieldToMaturityDeltaByDuration != null && GrossYieldToMaturityDeltaByDuration >= 0.02) && (GrossDuration != null && GrossDuration >= 0.5)',
                Filter,
              )
              .join('&&'),
            Select: dynamic.select(
              'symbol',
              'equityTypeName',
              'issuerName',
              'name',
              'grossYieldToMaturityDeltaByDuration',
              'grossDuration',
              'bondMidroogRank',
              'bondMidroogOutlook',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),

    updateIsActivePortfolioBonds: build.mutation<void, UpdateIsActivePortfolioBondsInput>({
      queryFn: async (input) => {
        try {
          const isAllChecked = input.items.every((item) => item.isActive);

          if (isAllChecked) {
            const ids = input.items.map(({ bondID }) => bondID);
            await API.api.bondsHelperLoadUserBondsAlertsByIDsCreate(ids);
            return { data: undefined };
          }

          const isAllUnchecked = input.items.every((item) => !item.isActive);

          if (isAllUnchecked) {
            const ids = input.items.map(({ bondID }) => bondID);
            await API.api.bondsHelperUnLoadUserBondsAlertsByIDsCreate(ids);
            return { data: undefined };
          }

          throw new AppError({ code: APP_ERROR_CODES.UNEXPECTED, message: 'not the same values' });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),

    bondsResetToUserDefault: build.mutation<void, void>({
      queryFn: async () => {
        try {
          await API.api.bondsHelperResetUserBondsAlertsToUserDefaultUpdate();
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
    bondsResetToSystemDefault: build.mutation<void, void>({
      queryFn: async () => {
        try {
          await API.api.bondsHelperResetUserBondsAlertsToSystemDefaultUpdate();
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),

    bondsLoadUserBondsAlertsBySymbols: build.mutation<string, string[]>({
      queryFn: async (input) => {
        try {
          const res = await API.api.bondsHelperLoadUserBondsAlertsBySymbolsCreate(input);
          return { data: res.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
    bondsLoadUserBondsAlertsByIssuers: build.mutation<string, string[]>({
      queryFn: async (input) => {
        try {
          const res = await API.api.bondsHelperLoadUserBondsAlertsByIssuersCreate(input);
          return { data: res.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
    bondsDeleteUserBondsAlerts: build.mutation({
      queryFn: async () => {
        try {
          await API.api.bondsHelperDeleteUserBondsAlertsDelete();
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
    bondsInsertNewUserBondsAlerts: build.mutation({
      queryFn: async () => {
        try {
          await API.api.bondsHelperInsertNewUserBondsAlertsCreate();
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),

    getGridAlertBondOnline1: build.query<GridAlertBondOnline1Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGetBondsOnline({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic.mergeFilters(yieldsFilter(filters)).join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'equityTypeID',
              'issuerName',
              'issuerID',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'linkedAssetName',
              'linkedAssetID',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridAlertBondOnline2: build.query<GridAlertBondOnline2Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGetBondsOnline({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic.mergeFilters(yieldsFilter(filters)).join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'equityTypeID',
              'issuerName',
              'issuerID',
              'name',
              'grossYieldToMaturity',
              'viewYieldBrutoLastDay',
              'calcYieldBrutoMoovers',
              'linkedAssetName',
              'linkedAssetID',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridAlertBondOnline3: build.query<GridAlertBondOnline3Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGetBondsOnline({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic.mergeFilters(yieldsFilter(filters)).join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'equityTypeID',
              'issuerName',
              'issuerID',
              'name',
              'grossYieldToMaturity',
              'grossYieldToMaturityDeltaByDuration',
              'linkedAssetName',
              'linkedAssetID',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridAlertBondOnline4: build.query<GridAlertBondOnline4Response, IPortfolioYieldsArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, UserProfileID, ...filters }) => {
        try {
          return await requestGetBondsOnline({
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Filter: dynamic.mergeFilters(yieldsFilter(filters)).join('&&'),
            Select: dynamic.select(
              'bondID',
              'lastRateDate',
              'symbol',
              'equityTypeName',
              'equityTypeID',
              'issuerName',
              'issuerID',
              'name',
              'grossYieldToMaturityDeltaByDuration',
              'viewSynDiffLastDay',
              'calcSynDiffMoovers',
              'linkedAssetName',
              'linkedAssetID',
              'grossDuration',
              'weekAverageNIS',
              'dailyTurnover',
              'bondMidroogRank',
              'bondMidroogOutlook',
              'bondMaalotRank',
              'bondMaalotOutlook',
              'ratingGroup',
            ),
            UserProfileID,
            Count: true,
          });
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),

    getGridSystemBonds: build.query<ISystemBondOutput, ISystemBondArg>({
      queryFn: async ({ take: Take, skip: Skip, userPatientProfileID, orderBy, ...filters }) => {
        try {
          const result = await requestGetPostSystem({
            userProfileID: userPatientProfileID,
            orderBy: dynamic.orderBy(orderBy),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'symbol',
                  filters.bonds,
                  dynamic.decoratorValueArray(dynamic.decoratorStringify(dynamic.equals)),
                ),
                dynamic.makeFilter(
                  'branchID',
                  filters.branchIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'linkedAssetID',
                  filters.linkedAssetIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'issuerID',
                  filters.issuerIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'equityTypeID',
                  filters.equityTypeIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'maalotRatingID',
                  filters.maalotRatingIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'midroogRatingID',
                  filters.midroogRatingIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', filters.isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            take: Take,
            skip: Skip,
            count: true,
          });

          return { data: result.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),

    bondsFillMissingRawDate: build.mutation<void, string>({
      queryFn: async (input) => {
        try {
          await requestFill(input);

          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.MIGRATIONS }],
    }),

    getBondGelemReportYieldLinked: build.query<
      Array<IBondGelemReportYield>,
      BaseBondsGelemReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondItemsDynamicCreate({
            select: SELECT_BOND_GELEM_REPORT_YIELD,
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.CPI,
          });

          const res = rtkAdapterDynamicToSource(response);
          return { data: res.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),
    getBondGelemReportStatsYieldLinked: build.query<
      Array<IDataBondStatItem>,
      BaseBondsGelemStatsReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondStatsCreate({
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.CPI,
            queryType: IQueryType.Yield,
            ratingGroup: input.ratingGroup,
          });
          return { data: response.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),

    getBondGelemReportYieldShekel: build.query<
      Array<IBondGelemReportYield>,
      BaseBondsGelemReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondItemsDynamicCreate({
            select: SELECT_BOND_GELEM_REPORT_YIELD,
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.ILS,
          });

          const res = rtkAdapterDynamicToSource(response);
          return { data: res.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),
    getBondGelemReportStatsYieldShekel: build.query<
      Array<IDataBondStatItem>,
      BaseBondsGelemStatsReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondStatsCreate({
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.ILS,
            queryType: IQueryType.Yield,
            ratingGroup: input.ratingGroup,
          });
          return { data: response.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),

    getBondGelemReportDeltaLinked: build.query<
      Array<IBondGelemReportDelta>,
      BaseBondsGelemReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondItemsDynamicCreate({
            select: SELECT_BOND_GELEM_REPORT_DELTA,
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.CPI,
          });

          const res = rtkAdapterDynamicToSource(response);
          return { data: res.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),
    getBondGelemReportStatsDeltaLinked: build.query<
      Array<IDataBondStatItem>,
      BaseBondsGelemStatsReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondStatsCreate({
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.CPI,
            queryType: IQueryType.Delta,
            ratingGroup: input.ratingGroup,
          });
          return { data: response.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),

    getBondGelemReportDeltaShekel: build.query<
      Array<IBondGelemReportDelta>,
      BaseBondsGelemReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondItemsDynamicCreate({
            select: SELECT_BOND_GELEM_REPORT_DELTA,
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.ILS,
          });

          const res = rtkAdapterDynamicToSource(response);
          return { data: res.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),
    getBondGelemReportStatsDeltaShekel: build.query<
      Array<IDataBondStatItem>,
      BaseBondsGelemStatsReportInput
    >({
      queryFn: async (input) => {
        try {
          const response = await API.api.bondsHelperGetGelemBondStatsCreate({
            symbols: input.symbols.join(',') || undefined,
            date: dateFormat(input.date, 'yyyy-MM-dd'),
            linkAssetType: ILinkAssetType.ILS,
            queryType: IQueryType.Delta,
            ratingGroup: input.ratingGroup,
          });
          return { data: response.data };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),
  }),
});
