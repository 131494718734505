import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { GridBondForPortfolio } from 'services/bond';
import { GetUserProfileBondsForProfileItem } from 'services/user-profile-bonds';
import { selectItemsAll, toggleItem } from 'utils/other';
import {
  actionProfileBondsCustomFetch,
  actionProfileBondsCustomRemove,
  actionProfileBondsSystemAttach,
  actionProfileBondsSystemDetach,
  actionProfileBondsSystemFetch,
} from './actions';
import { PREFIX } from './helpers';

interface SystemFilters {
  bonds: string[];
  issuerIDs: string[];
  branchIDs: string[];
  linkedAssetIDs: string[];
  equityTypeIDs: string[];
  maalotRatingIDs: string[];
  midroogRatingIDs: string[];
}

interface CustomFilters {
  bonds: string[];
  issuerIDs: string[];
}
interface BaseSubState<T, F> {
  isLoading: boolean;
  isSuccess: boolean;
  data: Array<T & { id: string; __isSelected: boolean }>;
  filters: F;
}

interface InitState {
  isInit: boolean;
  system: BaseSubState<GridBondForPortfolio, SystemFilters>;
  custom: BaseSubState<GetUserProfileBondsForProfileItem, CustomFilters>;
}

export const initState = (): InitState => {
  return {
    isInit: false,
    system: {
      isLoading: false,
      isSuccess: false,
      data: [],
      filters: {
        bonds: [],
        issuerIDs: [],
        branchIDs: [],
        linkedAssetIDs: [],
        equityTypeIDs: [],
        maalotRatingIDs: [],
        midroogRatingIDs: [],
      },
    },
    custom: {
      isLoading: false,
      isSuccess: false,
      data: [],
      filters: {
        bonds: [],
        issuerIDs: [],
      },
    },
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    selectCustomAll(state, action: PayloadAction<{ value: boolean }>) {
      selectItemsAll(state.custom.data, action.payload);
    },
    toggleCustom(state, action: PayloadAction<{ id: string }>) {
      toggleItem(state.custom.data, action.payload.id);
    },
    selectSystemAll(state, action: PayloadAction<{ value: boolean }>) {
      selectItemsAll(state.system.data, action.payload);
    },
    toggleSystem(state, action: PayloadAction<{ id: string }>) {
      toggleItem(state.system.data, action.payload.id);
    },
    setInit(state) {
      state.isInit = true;
    },

    setFiltersCustom(state, action: PayloadAction<Partial<CustomFilters>>) {
      state.custom.filters = { ...state.custom.filters, ...action.payload };
    },
    resetFiltersCustom(state) {
      state.custom.filters = initState().custom.filters;
    },

    setFiltersSystem(state, action: PayloadAction<Partial<SystemFilters>>) {
      state.system.filters = { ...state.system.filters, ...action.payload };
    },
    resetFiltersSystem(state) {
      state.system.filters = initState().system.filters;
    },

    reset() {
      return initState();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionProfileBondsSystemFetch.pending, (state) => {
        state.system.isLoading = true;
      })
      .addCase(actionProfileBondsSystemFetch.fulfilled, (state, action) => {
        state.system.isLoading = false;
        state.system.data = action.payload.map((item) => {
          return { ...item, __isSelected: false };
        });
      })
      .addCase(actionProfileBondsSystemFetch.rejected, (state) => {
        state.system.isLoading = false;
      });

    builder
      .addCase(actionProfileBondsCustomFetch.pending, (state) => {
        state.custom.isLoading = true;
      })
      .addCase(actionProfileBondsCustomFetch.fulfilled, (state, action) => {
        state.custom.isLoading = false;
        state.custom.isSuccess = true;
        state.custom.data = action.payload.map((item) => {
          return { ...item, __isSelected: false };
        });
      })
      .addCase(actionProfileBondsCustomFetch.rejected, (state) => {
        state.custom.isLoading = false;
      });

    builder
      .addCase(actionProfileBondsCustomRemove.pending, (state) => {
        state.custom.isLoading = true;
      })
      .addCase(actionProfileBondsCustomRemove.fulfilled, (state) => {
        state.custom.isLoading = false;
      })
      .addCase(actionProfileBondsCustomRemove.rejected, (state) => {
        state.custom.isLoading = false;
      });

    builder
      .addMatcher(
        isAnyOf(
          actionProfileBondsSystemAttach.pending.match,
          actionProfileBondsSystemDetach.pending.match,
        ),
        (state) => {
          state.system.isLoading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          actionProfileBondsSystemAttach.fulfilled.match,
          actionProfileBondsSystemDetach.fulfilled.match,
        ),
        (state) => {
          state.system.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          actionProfileBondsSystemAttach.rejected.match,
          actionProfileBondsSystemDetach.rejected.match,
        ),
        (state) => {
          state.system.isLoading = false;
        },
      );
  },
});
export const actionsProfileBonds = slice.actions;
export const reducerProfileBonds = slice.reducer;
