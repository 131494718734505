import MenuIcon from '@mui/icons-material/Menu';
import { ClickAwayListener, Drawer, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useOpen } from 'hooks';
import React from 'react';
import { MenuLogo } from '../menu-logo';
import { NavigationList } from '../navigation-list';
import style from './index.module.scss';

interface Props {
  isResponsive?: boolean;
}

export const Panel: React.FC<Props> = ({ isResponsive = false }) => {
  const {
    isOpen: isOpenDesktop,
    onToggle: onToggleDesktop,
    onClose: onCloseDesktop,
    onOpen: onOpenDesktop,
  } = useOpen();

  const {
    isOpen: isOpenMobile,
    onToggle: onToggleMobile,
    onClose: onCloseMobile,
    onOpen: onOpenMobile,
  } = useOpen();

  return (
    <>
      {!isResponsive ? (
        <ClickAwayListener onClickAway={onCloseDesktop}>
          <Drawer
            open={isOpenDesktop}
            variant={'permanent'}
            onClose={onCloseDesktop}
            classes={{
              paper: clsx(style.paper, isOpenDesktop && style.paperOpen),
            }}
          >
            <div className={style.inner}>
              <MenuLogo isOpen={isOpenDesktop} onToggle={onToggleDesktop} />
              <NavigationList
                isMenuOpen={isOpenDesktop}
                onMenuOpen={onOpenDesktop}
                onMenuClose={onCloseDesktop}
              />
            </div>
          </Drawer>
        </ClickAwayListener>
      ) : (
        <>
          <IconButton color={'primary'} size={'small'} onClick={onOpenMobile}>
            <MenuIcon />
          </IconButton>
          <Drawer
            open={isOpenMobile}
            onClose={onCloseMobile}
            classes={{
              paper: style.paperOpen,
            }}
          >
            <div className={style.inner}>
              <MenuLogo isOpen={isOpenMobile} onToggle={onToggleMobile} />
              <NavigationList
                isMenuOpen={isOpenMobile}
                onMenuOpen={onOpenMobile}
                onMenuClose={onCloseMobile}
              />
            </div>
          </Drawer>
        </>
      )}
    </>
  );
};
