import React, { useMemo } from 'react';
import { Stack, Typography, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import { dateFormat, DateValue } from 'utils/dates';
import { TIME_FORMAT_DATE_TIME } from 'configs/const';
import { APP_ROUTES } from 'configs';
import { Link } from 'react-router-dom';
import variables from 'styles/config.module.scss';
import style from './index.module.scss';
import { GRID_ONLINE_ALERT_TAB, ONLINE_ALERTS_TABS } from 'pages/online-alerts/helpers';

interface Props {
  entryDate: DateValue;
  name: string | null;
  disabled: boolean;
}
export const VariantFinished: React.FC<Props> = ({ entryDate, name }) => {
  const { tp } = useTranslate();
  const labelPayload = useMemo(() => {
    return {
      entryDate: dateFormat(entryDate, TIME_FORMAT_DATE_TIME),
      name: name || tp('name-system'),
    };
  }, [tp, name, entryDate]);

  return (
    <Tooltip title={tp('online-alerts-go-page-tooltip')}>
      <Stack
        className={style.root}
        direction={'row'}
        spacing={1}
        height={'100%'}
        component={Link}
        to={`${APP_ROUTES.ONLINE_ALERTS.path}?${GRID_ONLINE_ALERT_TAB}=${ONLINE_ALERTS_TABS.BOND_ONLINE_ALERT_1}`}
        color={variables.colorBlack}
      >
        <Typography
          component={'div'}
          lineHeight={1.1}
          alignSelf={'center'}
          flexGrow={1}
          pr={1}
          dangerouslySetInnerHTML={{
            __html: tp('etl-last-updated', labelPayload),
          }}
        />
      </Stack>
    </Tooltip>
  );
};
