interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}
const calcOffsetTop = (rect: Rect, value: Origin['vertical']) => {
  if (value === 'top') {
    return 0;
  }
  if (value === 'center') {
    return rect.height / 2;
  }
  return rect.height;
};
const calcOffsetLeft = (rect: Rect, value: Origin['horizontal'], isRevers: boolean) => {
  let defaultOffsets = [0, rect.width / 2, rect.width];
  let index = 2;

  if (value === 'left') {
    index = 0;
  }
  if (value === 'center') {
    index = 1;
  }

  let values = isRevers ? [...defaultOffsets].reverse() : defaultOffsets;

  return values[index];
};

interface Origin {
  vertical: 'top' | 'center' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
}
interface Options {
  anchorOrigin: Origin;
  transformOrigin: Origin;
  isRTL?: boolean;
}

export const calcPopupPosition = (
  targetEl: HTMLElement,
  anchorEl: HTMLElement,
  options: Options,
) => {
  const { isRTL = false, anchorOrigin, transformOrigin } = options;

  const rectAnchor = anchorEl.getBoundingClientRect();

  let anchorTop = rectAnchor.top + calcOffsetTop(rectAnchor, anchorOrigin.vertical);
  let anchorLeft = rectAnchor.left + calcOffsetLeft(rectAnchor, anchorOrigin.horizontal, isRTL);

  const rectTarget = targetEl.getBoundingClientRect();
  let top = anchorTop - calcOffsetTop(rectTarget, transformOrigin.vertical);
  let left = anchorLeft - calcOffsetLeft(rectTarget, transformOrigin.horizontal, isRTL);

  return { top, left, width: rectTarget.width, height: rectTarget.height };
};

export const makePopupVisible = (rect: Rect, viewportRect?: Rect) => {
  const rectTop = rect.top;
  const rectBottom = rect.top + rect.height;
  const rectLeft = rect.left;
  const rectRight = rect.left + rect.width;

  const viewportTop = viewportRect?.top || 0;
  const viewportLeft = viewportRect?.left || 0;

  let viewportWidth = viewportRect?.width || window.innerWidth;
  let viewportHeight = viewportRect?.height || window.innerHeight;

  viewportWidth += viewportLeft;
  viewportHeight += viewportTop;

  let newTop = rectTop;
  let newLeft = rectLeft;

  if (rectTop < 0) {
    newTop = 0;
  } else if (rectBottom > viewportHeight) {
    newTop = rectTop - (rectBottom - viewportHeight);
  }

  if (rectLeft < 0) {
    newLeft = 0;
  } else if (rectRight > viewportWidth) {
    newLeft = rectLeft - (rectRight - viewportWidth);
  }

  return {
    top: newTop,
    left: newLeft,
    height: rect.height,
    width: rect.width,
  };
};
