import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useRedirectPath = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const redirect_ = searchParams.get('redirect');

    return [location.pathname, redirect_ ? `redirect="${redirect_}"` : location.search]
      .filter(Boolean)
      .join('');
  }, [location.pathname, location.search, searchParams]);
};
