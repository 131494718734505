import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICurrentUserProfileResponse } from '__generated__/api';
import { updateAuthTokens } from 'utils/cookies';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';

export const actionAccountGetUser = createAsyncThunk<
  ICurrentUserProfileResponse,
  { isOnTheBackground: boolean },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountGetUser`, async (_) => {
  try {
    const { data } = await API.api.accountsGetCurrentAppUserList();
    return data;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
export const actionAccountGeneratePassword = createAsyncThunk<
  void,
  { email: string },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountGeneratePassword`, async (data) => {
  try {
    await API.api.accountsGeneratePasswordCreate(data);
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
export const actionAccountSedCode = createAsyncThunk<
  void,
  { email: string; code: string },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountSedCode`, async (data, { dispatch }) => {
  try {
    const { data: res } = await API.api.accountsLoginWithCodeCreate(data);
    updateAuthTokens({
      token: res?.jwt?.token || '',
      refreshToken: res?.jwt?.refreshToken || '',
      expires: res?.expires ? new Date(res.expires).toUTCString() : null,
    });
    const getUserResult = await dispatch(actionAccountGetUser({ isOnTheBackground: false }));

    if (actionAccountGetUser.rejected.match(getUserResult)) {
      throw getUserResult.error;
    }
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
export const actionAccountLogout = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `ACCOUNT/actionAccountLogout`,
  async () => {
    try {
      await API.api.accountsLogoutCreate();
      updateAuthTokens();
    } catch (e: unknown) {
      throw parseErrorData(e);
    }
  },
);
