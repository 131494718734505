import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: AppState) => state.reducerTradingEtl;

export const selectTradingEtlStatuses = createSelector(selectState, ({ isLoading }) => {
  return { isLoading };
});
export const selectTradingEtlData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectTradingEtlIsVisible = createSelector(selectTradingEtlData, (data) => {
  return Boolean(data);
});
