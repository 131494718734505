import React from 'react';
import { useAppSelector, useOpen, useTranslate } from 'hooks';
import {
  selectTradingEtlData,
  selectTradingEtlIsVisible,
  selectTradingEtlStatuses,
} from 'store/trading-etl/selectors';
import { TradingEtlData } from 'store/trading-etl';
import { Button, Stack, Theme, Tooltip, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { AppDraggable } from 'components/app-draggable';
import style from './index.module.scss';
import { ButtonFixed, VariantFinished } from 'components/trading-etl-tag/components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'configs';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import variables from 'styles/config.module.scss';

interface TradingEtlTagComponentProps {
  data: TradingEtlData;
}
export const TradingEtlTagComponent: React.FC<TradingEtlTagComponentProps> = ({ data }) => {
  const { isLoading } = useAppSelector(selectTradingEtlStatuses);
  const isResponsive = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { isOpen, onToggle } = useOpen();

  const { pathname } = useLocation();
  const { tp } = useTranslate();

  return (
    <>
      <ButtonFixed isOpen={isOpen} onClick={onToggle} />
      <AppDraggable axis={'x'} disabled={isResponsive}>
        <div className={clsx(style.root, isOpen && style.rootHidden)}>
          <DragIndicatorIcon
            sx={{
              color: variables.colorBorder,
              fontSize: '2rem',
              display: { xs: 'none', lg: 'block' },
            }}
          />
          <VariantFinished
            entryDate={data.entryDate}
            name={data.userProfile?.fullName ?? null}
            disabled={isLoading}
          />
          <Stack direction={'row'} spacing={0.3}>
            <Tooltip title={tp('etl-go-page-tooltip')}>
              <Button
                component={Link}
                to={APP_ROUTES.DATA_ETL.path}
                variant={'contained'}
                color={'secondaryLight'}
                className={style.button}
                disabled={pathname === APP_ROUTES.DATA_ETL.path}
              >
                <ReplyIcon />
              </Button>
            </Tooltip>
            <Tooltip title={tp('etl-close-tooltip')}>
              <Button
                onTouchStart={onToggle}
                onClick={onToggle}
                color={'inherit'}
                className={style.button}
                sx={{
                  color: variables.colorTextSecondary,
                }}
              >
                <CloseIcon fontSize={'small'} />
              </Button>
            </Tooltip>
          </Stack>
        </div>
      </AppDraggable>
    </>
  );
};

export const TradingEtlTag: React.FC = () => {
  const data = useAppSelector(selectTradingEtlData);
  const isVisible = useAppSelector(selectTradingEtlIsVisible);

  if (!data || !isVisible) {
    return null;
  }

  return <TradingEtlTagComponent data={data} />;
};
