import { ILanguage } from '__generated__/api';
import { AxiosResponse } from 'axios';
import { makeGetNextRank, makeReorderRows, patchFiles, postFiles } from 'modules/dynamic-service';
import * as dynamic from 'utils/dynamic';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';
import { makeUniqueFileName } from 'utils/file-uploader';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IGridLanguageArg, IGridLanguageResponse } from './models';

export * from './models';

const requestGet = API.api.languagesGetAllDynamicList;
const requestPost = API.api.languagesCreateCreate;
const requestPatch = API.api.languagesPatchPartialUpdate;
const requestDelete = API.api.languagesDeleteDelete;

const getNextRank = makeGetNextRank(requestGet, {
  field: 'rank',
});

const reorderRows = makeReorderRows<ILanguage>(requestPatch, {
  mainField: 'id',
  moveField: 'rank',
});

const REVALIDATE_KEY = RTK_TAGS.LANGUAGES;

export const apiLanguages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllLanguages: build.query<ILanguage[], void>({
      queryFn: async () => {
        try {
          const response = await requestGet({
            Select: dynamic.select(
              'id',
              'icon',
              'title',
              'culture',
              'rank',
              'direction',
              'isActive',
            ),
            OrderBy: dynamic.orderBy('rank', 'asc'),
          });

          return rtkAdapterDynamicToSource(response);
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
    }),
    getDefaultLanguageID: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.languagesGetDefaultLanguageList();
          return res as unknown as AxiosResponse<string>;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),

    getGridLanguages: build.query<IGridLanguageResponse, IGridLanguageArg>({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await requestGet({
            Select: dynamic.select(
              'id',
              'rank',
              'icon',
              'title',
              'culture',
              'direction',
              'isActive',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title'], search, dynamic.contains),
                dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });

          return result;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLanguage: build.query<ILanguage, string>({
      queryFn: async (id: string) => {
        try {
          const result = await requestGet({
            Filter: dynamic.mergeFilters(dynamic.makeFilter('id', id, dynamic.equals)).join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem(result);
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLanguage: build.mutation({
      queryFn: async (input: ILanguage) => {
        const [postData, transaction] = await postFiles(input, {
          icon: makeUniqueFileName('icon'),
        });
        try {
          const { rank } = await getNextRank({});
          const res = await requestPost({ ...postData, rank });

          return res;
        } catch (e: unknown) {
          transaction();
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLanguage: build.mutation({
      queryFn: async ({
        dataNew,
        dataOld,
      }: {
        dataNew: PatchPartial<ILanguage, 'id'>;
        dataOld?: PatchPartial<ILanguage, 'id'>;
      }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          icon: makeUniqueFileName('icon'),
        });
        try {
          return requestPatch(...makePatchArgs(patchData));
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    deleteLanguage: build.mutation<ILanguage, PatchPartial<ILanguage, 'id'>>({
      queryFn: async (data) => {
        try {
          const result = await requestDelete(String(data.id));
          return result;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data.id) },
      ],
    }),
    moveLanguages: build.mutation<
      void,
      { newRows: Partial<ILanguage>[]; oldRows: Partial<ILanguage>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await reorderRows({
            newRows,
            oldRows,
          });
          return { data: undefined };
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id || '' })),
      ],
    }),
  }),
});
