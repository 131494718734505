import React, { memo, useEffect, useState } from 'react';
import { getAuthTokens } from 'utils/cookies';
import { apiApp, apiFree } from 'utils/service';

let isRefreshing = false;
let refreshingCall: Promise<any>;

type SuccessArg = { token: string; refreshToken: string; expires: string };
interface Props {
  onRefresh: () => Promise<SuccessArg>;
  onSuccess: (p: SuccessArg) => void;
  onError: (e: Error) => void;
  children: React.ReactNode;
}

export const AxiosInterceptorsRefreshToken = memo<Props>(
  ({ children, onRefresh, onSuccess, onError }) => {
    const [canRender, setCanRender] = useState(false);
    useEffect(() => {
      setCanRender(true);
      const ID = apiApp.interceptors.response.use(
        (response) => response,
        (error) => {
          const status = error?.response?.status || null;

          const { token, refreshToken } = getAuthTokens();

          if (status !== 401 || !(token && refreshToken)) {
            return Promise.reject(error);
          }

          if (isRefreshing) {
            return refreshingCall.then(() => {
              return apiApp(error.config);
            });
          }
          isRefreshing = true;
          refreshingCall = onRefresh()
            .then((res) => {
              isRefreshing = false;
              const { token, refreshToken, expires } = res;
              onSuccess({ token, refreshToken, expires });
              return apiFree({
                ...error.config,
                headers: { ...error.config.headers, Authorization: 'Bearer ' + token },
              });
            })
            .catch((err) => {
              isRefreshing = false;
              onError(err);
              return Promise.reject(err);
            });
          return refreshingCall;
        },
      );

      return () => {
        apiApp.interceptors.response.eject(ID);
      };
    }, [onRefresh, onSuccess, onError]);

    return <>{canRender ? children : null}</>;
  },
);
