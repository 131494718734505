import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectAccountUser } from 'store/auth';
import { actionTradingEtlEndPolling, actionTradingEtlStartPolling } from 'store/trading-etl';

interface Props {
  children: React.ReactNode;
}
export const TradingEtlProvider = memo<Props>(({ children }) => {
  const user = useAppSelector(selectAccountUser);
  const dispatch = useAppDispatch();
  const appUserID = user?.appUserID;

  useEffect(() => {
    if (!appUserID) return;
    dispatch(actionTradingEtlStartPolling());
    return () => {
      dispatch(actionTradingEtlEndPolling());
    };
  }, [appUserID, dispatch]);

  return <>{children}</>;
});
