import { createSelector } from '@reduxjs/toolkit';
import { isEqual, keyBy } from 'lodash-es';
import { filtersSomeIDs } from 'utils/front-filters';
import { AppState } from '../index';
import { initState } from './slice';

const selectState = (state: AppState) => state.profileStocks;

export const selectProfileStocksInit = createSelector(selectState, ({ isInit }) => {
  return isInit;
});

export const selectProfileStocksCustomState = createSelector(selectState, ({ custom }) => {
  return custom;
});
export const selectProfileStocksCustomFilters = createSelector(
  selectProfileStocksCustomState,
  ({ filters }) => {
    return filters;
  },
);
export const selectProfileStocksCustomHasFilters = createSelector(
  selectProfileStocksCustomFilters,
  (filters) => {
    return !isEqual(filters, initState().custom.filters);
  },
);

const selectProfileStocksCustomData = createSelector(selectProfileStocksCustomState, ({ data }) => {
  return data;
});
export const selectProfileStocksCustomDataFiltered = createSelector(
  selectProfileStocksCustomData,
  selectProfileStocksCustomFilters,
  (data, filters) => {
    return data.filter((row) => {
      return [
        filtersSomeIDs(filters.stocks, row.symbol),
        filtersSomeIDs(filters.issuerIDs, row.issuerID),
      ].every(Boolean);
    });
  },
);
export const selectProfileStocksCustomDataSelected = createSelector(
  selectProfileStocksCustomDataFiltered,
  (data) => {
    return data.filter((item) => item.__isSelected);
  },
);

export const selectProfileStocksCustomDataAnySelected = createSelector(
  selectProfileStocksCustomDataSelected,
  (selected) => {
    return selected.length > 0;
  },
);
export const selectProfileStocksCustomDataAllSelected = createSelector(
  selectProfileStocksCustomDataFiltered,
  selectProfileStocksCustomDataSelected,
  selectProfileStocksCustomDataAnySelected,
  (all, selected, anySelected) => {
    return anySelected && all.length === selected.length;
  },
);

const selectProfileStocksCustomMap = createSelector(selectProfileStocksCustomData, (data) => {
  return keyBy(data, 'stockID');
});

export const selectProfileStocksSystemState = createSelector(selectState, ({ system }) => {
  return system;
});
export const selectProfileStocksSystemFilters = createSelector(
  selectProfileStocksSystemState,
  ({ filters }) => {
    return filters;
  },
);
export const selectProfileStocksSystemHasFilters = createSelector(
  selectProfileStocksSystemFilters,
  (filters) => {
    return !isEqual(filters, initState().system.filters);
  },
);
const selectProfileStocksSystemData = createSelector(
  selectProfileStocksSystemState,
  selectProfileStocksCustomMap,
  ({ data }, map) => {
    return data.map((item) => ({
      ...item,
      __isInCustom: !!map[String(item.id)],
    }));
  },
);

export const selectProfileStocksSystemDataFiltered = createSelector(
  selectProfileStocksSystemData,
  selectProfileStocksSystemFilters,
  (data, filters) => {
    return data.filter((row) => {
      return [
        filtersSomeIDs(filters.stocks, row.symbol),
        filtersSomeIDs(filters.equityTypeIDs, row.equityTypeID),
        filtersSomeIDs(filters.branchIDs, row.branchID),
        filtersSomeIDs(filters.issuerIDs, row.issuerID),
      ].every(Boolean);
    });
  },
);

export const selectProfileStocksSystemDataSelected = createSelector(
  selectProfileStocksSystemDataFiltered,
  (data) => {
    return data.filter((item) => item.__isSelected);
  },
);

export const selectProfileStocksSystemDataAnySelected = createSelector(
  selectProfileStocksSystemDataSelected,
  (selected) => {
    return selected.length > 0;
  },
);
export const selectProfileStocksSystemDataAllSelected = createSelector(
  selectProfileStocksSystemDataFiltered,
  selectProfileStocksSystemDataSelected,
  selectProfileStocksSystemDataAnySelected,
  (all, selected, anySelected) => {
    return anySelected && all.length === selected.length;
  },
);

export const selectProfileStocksLoading = createSelector(
  selectProfileStocksSystemState,
  selectProfileStocksCustomState,
  (system, custom) => {
    return system.isLoading || custom.isLoading;
  },
);
