import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  decoratorGetArgsAsPost,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  GetUserProfileStockAlertsInput,
  GetUserProfileStockAlertsOutput,
  IUserProfileGridStocks,
  UserProfileStockArg,
} from './models';

import { IUserProfileStock } from '__generated__/api';
import { AxiosResponse } from 'axios';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';

export * from './models';

const requestGet = API.api.userProfileStocksGetAllDynamicList;
const requestGetPost = decoratorGetArgsAsPost(API.api.userProfileStocksGetAllItemsDynamicCreate);
const requestPatch = API.api.userProfileStocksPatchPartialUpdate;

export const apiUserProfileStocks = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridUserProfileStocks: build.query<IUserProfileGridStocks, UserProfileStockArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, ...filters }) => {
        try {
          const res = await requestGetPost({
            Select: dynamic.select(
              'id',
              'stockID',
              'stock.symbol',
              'stock.name',
              'stock.controllingShareHolder',
              'stock.issuerNum as issuerNum',
              'stock.issuer.title as issuerName',
              'stock.equityType.title as equityTypeName',
              'stock.branch.title as branchName',
              'stock.baseRate',
              'stock.lastDealRate',
              'stock.baseRateChangePercentage',
              'stock.weekYield',
              'stock.allMonthYield',
              'stock.beginOfYearYield',
              'stock.allYearYield',
              'stock.pbRatio',
              'stock.currentMarketWorth',
              'stock.weekAverageTurnover',
              'stock.dailyTurnover',
              'stock.listedFortune',
              'stock.dividendYield',
              'stock.peRetio',
              'stock.yesterdayYield',
              'stock.issuingDate',
              'stock.lastRateDate',
              'stock.tradingStage',
              'stock.issuerID',
              'stock.branchID',
              'stock.equityTypeID',
              'stock.tradeStatusID',
              'stock.countryOfIncorporation',
            ),
            OrderBy: dynamic.orderBy(orderBy),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', filters.userProfileID, dynamic.equals),
                dynamic.makeFilter(
                  'symbol',
                  filters.stocks,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter('stock.equityTypeID', filters.equityTypeIDs, dynamic.equalsSome),
                dynamic.makeFilter('stock.branchID', filters.branchIDs, dynamic.equalsSome),
                dynamic.makeFilter('isActive', filters.isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            Take,
            Skip,
            Count: true,
          });
          return res as AxiosResponse<IUserProfileGridStocks>;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getUserProfileStockAlerts: build.query<
      GetUserProfileStockAlertsOutput,
      GetUserProfileStockAlertsInput
    >({
      queryFn: async ({ userProfileID, stockID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'stockID',
              'userProfileID',
              'stock.symbol',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'alert10',
              'alert11',
              'alert12',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals),
                dynamic.makeFilter('stockID', stockID, dynamic.equals),
              )
              .join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem<GetUserProfileStockAlertsOutput>(res as unknown as any);
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    patchUserProfileStockAlert: build.mutation<
      void,
      PatchPartial<IUserProfileStock, 'id' | 'userProfileID' | 'stockID'>
    >({
      queryFn: async (input) => {
        try {
          const { userProfileID, stockID, ...rest } = input;
          await requestPatch(...makePatchArgs(rest));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
  }),
});
