import { actionAccountGetUser } from 'store/auth/actions';
import { actionAccountRefresh } from 'store/auth/slice';
import { debounce, put, select, throttle } from 'typed-redux-saga';
import { apiUserProfilePermissions } from 'services/user-profile-permissions';
import { selectAccountPermissions, selectAccountUser } from './selectors';
import { apiUserProfiles } from 'services/user-profiles';
import { ActionMatchingPattern } from '@redux-saga/types';

function* sagaRefreshAccount() {
  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
}
function* sagaWatchUpdatePermissions(
  action: ActionMatchingPattern<
    typeof apiUserProfilePermissions.endpoints.patchUserProfilePermission.matchFulfilled
  >,
) {
  const permissions = yield* select(selectAccountPermissions);

  if (!permissions) return;

  if (permissions.id !== action.meta.arg.originalArgs.id) return;

  yield* put(actionAccountRefresh());
}
function* sagaWatchUpdateUser(
  action: ActionMatchingPattern<typeof apiUserProfiles.endpoints.patchUserProfile.matchFulfilled>,
) {
  const user = yield* select(selectAccountUser);

  if (!user) return;

  if (user.appUserID !== action.meta.arg.originalArgs.dataOld?.appIdentityUserID) return;

  yield* put(actionAccountRefresh());
}

export const sagasAccounts = [
  debounce(3000, actionAccountRefresh, sagaRefreshAccount),
  throttle(3000, apiUserProfiles.endpoints.patchUserProfile.matchFulfilled, sagaWatchUpdateUser),
  debounce(
    1000,
    apiUserProfilePermissions.endpoints.patchUserProfilePermission.matchFulfilled,
    sagaWatchUpdatePermissions,
  ),
];
