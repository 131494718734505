import { createAsyncThunk } from '@reduxjs/toolkit';
import { GridBondForPortfolio } from 'services/bond';
import { apiBondsHelper } from 'services/bonds-helper';
import { GetUserProfileBondsForProfileItem } from 'services/user-profile-bonds';
import * as dynamic from 'utils/dynamic';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import {
  selectProfileBondsCustomDataSelected,
  selectProfileBondsSystemDataSelected,
} from './selectors';

export const actionProfileBondsSystemFetch = createAsyncThunk<
  Array<GridBondForPortfolio>,
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/fetchSystem`, async (_) => {
  try {
    const { data } = await API.api.bondsGetAllDynamicList({
      Select: dynamic.select(
        'id',
        'symbol',
        'branchID',
        'linkedAssetID',
        'issuerID',
        'equityTypeID',
        'maalotRatingID',
        'midroogRatingID',
        'name',
        'issuerNum',
        'issuer.title as issuerName',
        'equityType.title as equityTypeName',
        'branch.title as branchName',
        'linkedAsset.title as linkedAssetName',
        'bondMidroogRank',
        'bondMaalotRank',
      ),
      Filter: dynamic.mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals)).join('&&'),
    });

    return data.items as unknown as Array<GridBondForPortfolio>;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});

export const actionProfileBondsCustomFetch = createAsyncThunk<
  Array<GetUserProfileBondsForProfileItem>,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/fetchCustom`, async ({ userProfileID }) => {
  try {
    const { data } = await API.api.userProfileBondsGetAllDynamicList({
      Select: dynamic.select(
        'id',
        'bondID',
        'userProfileID',
        'alert1',
        'alert2',
        'alert3',
        'alert4',
        'alert5',
        'alert6',
        'alert7',
        'alert8',
        'bond.symbol',
        'bond.name',
        'bond.issuerName',
        'bond.issuerID',
        'bond.controllingShareHolder',
        'bond.issuerNum',
        'bond.equityTypeName',
        'bond.branchName',
        'bond.baseRate',
        'bond.lastDealRate',
        'bond.baseRateChangePercentage',
        'bond.yesterdayYield',
        'bond.beginOfYearYield',
        'bond.grossYieldToMaturity',
        'bond.grossDuration',
        'bond.grossYieldToMaturityDeltaByDuration',
        'bond.linkedAssetName',
        'bond.weekAverageNIS',
        'bond.dailyTurnover',
        'bond.annualInterest',
        'bond.grossAdjustedValue',
        'bond.listedFortune',
        'bond.redemptionOrExpirationDate',
        'bond.issuingDate',
        'bond.lastRateDate',
        'bond.tradingStage',
        'bond.bondMidroogRank',
        'bond.bondMidroogOutlook',
        'bond.bondMaalotRank',
        'bond.bondMaalotOutlook',
        'bond.flowYear1',
        'bond.flowYear2',
        'bond.flowYear3',
        'bond.flowYear4',
        'bond.flowYear5',
        'bond.flowYear6',
        'bond.flowYear7',
        'bond.flowYearElse',
      ),
      Filter: dynamic
        .mergeFilters(dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals))
        .join('&&'),
    });

    return data.items as unknown as Array<GetUserProfileBondsForProfileItem>;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});

export const actionProfileBondsCustomRemove = createAsyncThunk<
  void,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/removeCustom`, async ({ userProfileID }, { getState, dispatch }) => {
  try {
    const selected = selectProfileBondsCustomDataSelected(getState());

    const items = selected.map(({ bondID }) => ({
      bondID: String(bondID),
      isActive: false,
    }));

    await dispatch(
      apiBondsHelper.endpoints.updateIsActivePortfolioBonds.initiate({
        userProfileID,
        items,
      }),
    ).unwrap();

    return undefined;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});

export const actionProfileBondsSystemAttach = createAsyncThunk<
  void,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/systemAttach`, async ({ userProfileID }, { getState, dispatch }) => {
  try {
    const selected = selectProfileBondsSystemDataSelected(getState());

    const items = selected
      .filter((row) => {
        return !row.__isInCustom;
      })
      .map(({ id }) => ({
        bondID: id,
        isActive: true,
      }));

    await dispatch(
      apiBondsHelper.endpoints.updateIsActivePortfolioBonds.initiate({
        userProfileID,
        items,
      }),
    ).unwrap();

    return undefined;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});

export const actionProfileBondsSystemDetach = createAsyncThunk<
  void,
  { userProfileID: string },
  AppAsyncThunkConfig
>(`${PREFIX}/systemDetach`, async ({ userProfileID }, { getState, dispatch }) => {
  try {
    const selected = selectProfileBondsSystemDataSelected(getState());

    const items = selected
      .filter((row) => {
        return row.__isInCustom;
      })
      .map(({ id }) => ({
        bondID: id,
        isActive: false,
      }));

    await dispatch(
      apiBondsHelper.endpoints.updateIsActivePortfolioBonds.initiate({
        userProfileID,
        items,
      }),
    ).unwrap();

    return undefined;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
