import { BoxProps, CircularProgress, CircularProgressProps, Box } from '@mui/material';
import clsx from 'clsx';
import { memo } from 'react';

interface Classes {
  root: string;
  icon: string;
}
interface Props extends BoxProps {
  IconProps?: CircularProgressProps;
  classes?: Partial<Classes>;
}
export const AppLoading = memo<Props>(({ IconProps, className, classes, ...rest }) => {
  return (
    <Box
      className={clsx(className, classes?.root)}
      position={'absolute'}
      top={0}
      right={0}
      bottom={0}
      left={0}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      zIndex={1000}
      {...rest}
      sx={{
        ...rest.sx,
        background: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <CircularProgress {...IconProps} />
    </Box>
  );
});
