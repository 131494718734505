import * as dynamic from 'utils/dynamic';

import { ISystemBondDefaultAlert, IUserProfileBondDefaultAlert } from '__generated__/api';
import { equals } from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IUserProfileBondDefaultAlertArg } from './models';

const REVALIDATE_KEY = RTK_TAGS.USER_PROFILE_BOND_DEFAULT_ALERTS;

const requestGet = API.api.userProfileBondDefaultAlertsGetAllDynamicList;
const requestPatch = API.api.userProfileBondDefaultAlertsPatchPartialUpdate;

export * from './models';

export const apiUserProfileBondDefaultAlerts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getBondDefaultAlerts: build.query<
      IUserProfileBondDefaultAlert,
      IUserProfileBondDefaultAlertArg
    >({
      queryFn: async ({ userProfileID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
            ),
            Filter: dynamic.makeFilter('userProfileID', userProfileID, equals),
            Count: true,
          });
          return rtkAdapterDynamicItem<ISystemBondDefaultAlert>(res as unknown as any);
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchBondDefaultAlert: build.mutation<void, PatchPartial<IUserProfileBondDefaultAlert, 'id'>>({
      queryFn: async (data) => {
        try {
          await requestPatch(...makePatchArgs(data));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
        { type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' },
      ],
    }),
  }),
});
