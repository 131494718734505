import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useLanguage } from 'hooks/use-language';
import React, { memo, useEffect } from 'react';
import { AppThemeOptions } from 'styles/theme';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

export const themeLtr = createTheme(AppThemeOptions);
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

export const themeRtl = createTheme({ ...AppThemeOptions, direction: 'rtl' });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

interface Props {
  children: React.ReactNode;
}
export const AppThemeProvider = memo<Props>(({ children }) => {
  const { isRTL } = useLanguage();
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [isRTL]);
  const theme = isRTL ? themeRtl : themeLtr;
  const cache = isRTL ? cacheRtl : cacheLtr;

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
});
