import { AxiosResponse } from 'axios';
import * as dynamic from 'utils/dynamic';
import { decoratorIsNotNullable } from 'utils/dynamic';
import { API, apiRtk, makePatchArgs, RTK_TAGS, rtkAdapterError } from 'utils/service';
import { IGridDataEtlArg, IGridDataEtlResponse } from './models';

const requestGet = API.api.dataEtLsGetAllDynamicList;
const requestPatch = API.api.dataEtLsPatchPartialUpdate;

export * from './models';

const REVALIDATE_KEY = RTK_TAGS.DATA_ETL;

export const apiDataEtl = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    cancelDataEtl: build.mutation<void, string>({
      queryFn: async (id) => {
        try {
          await requestPatch(...makePatchArgs({ id, finished: true }));

          return { data: undefined };
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridDataEtls: build.query<IGridDataEtlResponse, IGridDataEtlArg>({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, date, userProfileID }) => {
        try {
          const result = await requestGet({
            Select: dynamic.select(
              'id',
              'entryDate',
              'finished',
              'userProfileID',
              'userProfile',
              'logSourceID',
              'logSource',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  ['userProfile.firstName', 'userProfile.lastName', 'logSource.title'],
                  search,
                  dynamic.contains,
                ),
                dynamic.makeFilter('entryDate', date, dynamic.dateRangeISO),
                dynamic.makeFilter(
                  'userProfile.id',
                  userProfileID,
                  decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result as AxiosResponse<IGridDataEtlResponse>;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchDataEtl: build.mutation({
      queryFn: async (data) => {
        try {
          await requestPatch(...makePatchArgs(data));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
