import { TranslateMap, TranslateProps } from 'configs';
import { useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { selectAccountLabelsMap } from 'store/auth';
import { calcTranslate } from 'utils/other';
import { ILabel } from '__generated__/api';

export const getTranslateValue = (map: Record<string, ILabel | undefined>, key: string) => {
  const label = map[key];
  if (!label && import.meta.env.PROD) {
    console.log(`%c "${key}" %c not found please add it to crm`, 'color: red;', 'color: black;');
  }
  return label?.title || key;
};

export const useTranslate = () => {
  const map = useAppSelector(selectAccountLabelsMap);
  const t = useCallback(
    (key: string) => {
      return getTranslateValue(map, key);
    },
    [map],
  );

  const tp = useCallback(
    <T extends keyof TranslateMap>(...params: TranslateProps<T>) => {
      return params[1] ? calcTranslate(t(params[0]), params[1]) : t(params[0]);
    },
    [t],
  );
  return { t, tp };
};
