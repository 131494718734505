import { Box, Paper } from '@mui/material';
import React, { memo, useEffect, useRef } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { Footer, Header } from './components';
import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const outlet = useOutlet();
  const refPrevOutlet = useRef<React.ReactNode>();

  const { pathname } = useLocation();

  useEffect(() => {
    refPrevOutlet.current = outlet;
  }, [outlet]);
  useEffect(() => {}, [pathname]);

  return (
    <Box className={style.wrap}>
      <Header pageTitle={''} />
      <Paper className={style.paper}>
        <div>{outlet}</div>
      </Paper>
      <Footer />
    </Box>
  );
});
