import React, { useState } from 'react';
import Draggable, { DraggableEventHandler, DraggableProps } from 'react-draggable';

interface Props extends Omit<Partial<DraggableProps>, 'onStart' | 'onStop'> {}

export const AppDraggable: React.FC<Props> = (props) => {
  let [dragInfo, setDragInfo] = useState({ x: 0, y: 0, time: 0 });

  let handleDragStart: DraggableEventHandler = (e, data) => {
    setDragInfo({
      x: data.x,
      y: data.y,
      time: Date.now(),
    });
  };

  let handleDragStop: DraggableEventHandler = (e, data) => {
    if (!dragInfo) return;
    let change = {
      x: Math.abs(data.x - dragInfo.x),
      y: Math.abs(data.y - dragInfo.y),
      time: Date.now() - dragInfo.time,
    };
    if (change.x + change.y <= 10 && change.time < 300) {
      // debugger;
      // let eClick = new Event('click');
      // e.target?.dispatchEvent(eClick);
      //@ts-ignore
      e.target?.click?.();
      // console.log(e.target);
    }
  };
  return <Draggable {...props} onStart={handleDragStart} onStop={handleDragStop} />;
};
