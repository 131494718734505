import { select } from 'utils/dynamic';

export const SELECT_BOND_GELEM_REPORT_YIELD = select(
  'id',
  'name',
  'ratingGroup',
  'bondMidroogRank',
  'grossDuration',
  'symbol',
  'grossYieldToMaturity',
);
export const SELECT_BOND_GELEM_REPORT_DELTA = select(
  'id',
  'name',
  'ratingGroup',
  'bondMidroogRank',
  'grossDuration',
  'symbol',
  'grossYieldToMaturityDeltaByDuration',
);

export const SELECT_BOND_GELEM_REPORT = select(
  'id',
  'symbol',
  'equityTypeName',
  'name',
  'grossYieldToMaturity',
  'grossYieldToMaturityDeltaByDuration',
  'grossDuration',
  'issuerName',
  'issuerNum',

  'bondMidroogRank',
  'viewMidroogRatingRank',
  'bondMidroogOutlook',

  'bondMaalotRank',
  'bondMaalotOutlook',
);
