import { Link as MaterialLink, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import { memo } from 'react';
import style from './index.module.scss';

const SITE_URL = import.meta.env.VITE_WEBSITE_URL;
interface Props {}

export const Footer = memo<Props>(() => {
  const { tp } = useTranslate();

  return (
    <footer className={style.footer}>
      <Typography color={'text.secondary'}>{tp('copyright')}</Typography>

      <div className={style.links}>
        <MaterialLink
          color={'text.secondary'}
          variant={'body1'}
          underline={'hover'}
          href={SITE_URL}
          target={'_blank'}
        >
          {tp('web-site')}
        </MaterialLink>
      </div>
    </footer>
  );
});
