import { memo } from 'react';
import { Outlet } from 'react-router-dom';

export const EmptyLayout = memo((props) => {
  return (
    <>
      <Outlet />
    </>
  );
});
