import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { DateValue } from 'utils/dates';
import {
  actionTradingEtlFetchData,
} from './actions';
import { apiDataEtl } from 'services/data-etl';

export type TradingEtlData = {
  id: string;
  finished: boolean;
  entryDate: DateValue;
  userProfile: null | { appIdentityUserID: string; fullName: string };
};

interface InitState {
  isInit: boolean;
  isPolling: boolean;
  isLoading: boolean;
  error: null | SerializedError;
  data: null | TradingEtlData;
}

const initStateAccount = (): InitState => {
  return {
    isInit: false,
    isPolling: false,
    isLoading: false,
    error: null,
    data: null,
  };
};

const slice = createSlice({
  name: 'TRADING_ETL',
  initialState: initStateAccount(),
  reducers: {
    actionTradingEtlStartPolling(state) {
      state.isPolling = true;
      state.isInit = true;
    },
    actionTradingEtlEndPolling(state) {
      state.isPolling = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionTradingEtlFetchData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionTradingEtlFetchData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(actionTradingEtlFetchData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });

    builder.addMatcher(apiDataEtl.endpoints.cancelDataEtl.matchFulfilled, (state, action) => {
      // skip if data is not exist
      if (!state.data) {
        return state;
      }
      // skip if data item and request item are different items
      if (state.data.id !== action.meta.arg.originalArgs) {
        return state;
      }
      state.data.finished = true;
    });
  },
});
export const { actionTradingEtlStartPolling, actionTradingEtlEndPolling } = slice.actions;
export const reducerTradingEtl = slice.reducer;
