import { Unset } from './numbers';

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  ...args: Partial<Model>[]
) => {
  const argsRevers = args.filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find(
      (item) => item.hasOwnProperty(key) && item[key] !== '' && item[key] !== null,
    );
    acc[key] = targetValue ? targetValue[key] : model[key];
    return acc;
  }, {}) as Model;
};
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const getRandomString = (length = 10) => {
  const arr = new Uint8Array((length || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};
export const omit = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> =>
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key as K))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {} as Omit<T, K>);

export const createMap = <M extends Record<string, any>>(source: M[], key: keyof M) => {
  return source.reduce(
    (acc, item) => {
      acc[item[key]] = item;
      return acc;
    },
    {} as Record<string, M | undefined>,
  );
};

export const throttle = <T extends Function>(callback: T, limit: number) => {
  let waiting = false;
  let latestArguments: Array<any> | null = null;
  let latestContext: any | null = null;

  function wrapper(...args: any[]) {
    if (waiting) {
      latestArguments = args;
      // @ts-ignore
      latestContext = this;
      return;
    }

    // @ts-ignore
    callback.apply(this, args);

    waiting = true;

    setTimeout(function () {
      waiting = false;

      if (latestArguments) {
        callback.apply(latestContext, latestArguments);

        latestArguments = null;
        latestContext = null;
      }
    }, limit);
  }

  return wrapper;
};

export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};
const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{[a-zA-Z]+?}}/g, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] === undefined ? '-- --' : payload[key];
  });
};
type AnyFunction = (...args: any[]) => any;
export const composeFunctions = <T extends (...args: any[]) => any>(
  cb: T,
  ...functions: (AnyFunction | undefined)[]
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    (functions.filter(Boolean) as AnyFunction[]).forEach((fn) => {
      fn(...args);
    });
    return result;
  };
};

export const replaceSpace = (value: string) => value.replace(/ /g, '');
type BaseItem = { id: Unset; __isSelected: boolean };

export const selectItemsAll = <T extends BaseItem>(items: Array<T>, option: { value: boolean }) => {
  items.forEach((item) => {
    item.__isSelected = option.value;
  });
};
export const toggleItem = <T extends BaseItem>(items: Array<T>, id: string) => {
  const item = items.find((item) => item.id === id);

  if (item) {
    item.__isSelected = !item.__isSelected;
  }
};
