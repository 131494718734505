import { DraggableCalcInitPosition } from 'components/draggable-modal';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { convertToDate, DateValue } from './dates';
import { makePopupVisible } from './elements';
import { interpolation, Unset } from './numbers';

export const getFullNameInitials = (fullName: string | null | undefined) => {
  if (!fullName) {
    return '';
  }

  const names = fullName.trim().split(' ');
  const firstLetters = names.map((word) => word.trim()[0]).filter(Boolean);

  return firstLetters.map((letter) => letter.toUpperCase()).join('');
};

export interface BondOption {
  name?: Unset<string | number>;
  symbol?: Unset<string | number>;
}

export const makeUniqueBonds = <T extends BondOption>(options: T[]) => {
  const uniqueItems = options.reduce((acc: { [key: string]: T }, item) => {
    const key = `${item.name} - ${item.symbol}`;
    if (!acc[key]) {
      acc[key] = item;
    }
    return acc;
  }, {});

  return Object.values(uniqueItems);
};

export const makeSourcesByMultiple = <T extends Record<string, any>, K extends keyof T>(
  options: T[],
  fields: K[],
) => {
  const mainMap = new Map<string, Map<string, Array<T[K]>>>();

  options.forEach((option) => {
    fields.forEach((field) => {
      const key = String(field);
      if (!mainMap.has(key)) {
        mainMap.set(key, new Map());
      }

      const innerMap = mainMap.get(key);

      if (!innerMap) {
        throw new Error();
      }

      const innerKey = option[field];

      if (!innerMap.has(innerKey)) {
        innerMap.set(innerKey, innerKey);
      }
    });
  });

  return fields.reduce(
    (acc, field) => {
      const innerItems = mainMap.get(String(field));
      acc[field] = innerItems
        ? (Array.from(innerItems.keys()) as T[K][])
            .filter(Boolean)
            .map((value) => ({ id: String(value).trim(), title: value }))
        : [];
      return acc;
    },
    {} as Record<K, Array<{ id: string; title: any }>>,
  );
};

export const findNumbersIntoString = (value: string) => {
  const separated = value.split(/[|,\n]/gi).map((item) => item.trim());

  const filtered = separated.filter((item) => new RegExp('^\\d{1,10}$').test(item));

  // remove duplicates
  return Array.from(new Set(filtered).values());
};

export const calcInitCenterPosition: DraggableCalcInitPosition = (paperEl, isRTL = false) => {
  const rect = paperEl.getBoundingClientRect();

  const viewportElem = document.getElementsByTagName('body')[0];
  const viewport = viewportElem ? viewportElem.getBoundingClientRect() : undefined;

  let parentCenterX = 0;
  let parentCenterY = 0;

  if (viewport) {
    parentCenterX = viewport.left + viewport.width / 2;
    parentCenterY = viewport.top + viewport.height / 2;
  }

  const childTop = parentCenterY - rect.height / 2;
  const childLeft = parentCenterX - rect.width / 2;

  const centeredChild = {
    ...rect,
    top: childTop,
    left: childLeft,
  };

  const { left, top } = makePopupVisible(centeredChild, viewport);

  return { x: isRTL ? -left : left, y: top };
};

type MapType<T extends Record<string, string>> = {
  [K in keyof T]: T[K];
};
type Invert<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T as T[P]]: P;
};

export class ObjectAdapter<M extends MapType<M>> {
  private readonly map: M;

  constructor(map: M) {
    this.map = map;
  }

  run<T extends Record<string, any>>(input: T) {
    return Object.entries(this.map).reduce(
      (acc, [key, value]) => {
        //@ts-ignore
        acc[value] = input[key];
        return acc;
      },
      {} as { [K in keyof Invert<M>]: T[Invert<M>[K]] },
    );
  }
  runReverse<T extends Record<string, any>>(input: T) {
    return Object.entries(this.map).reduce(
      (acc, [key, value]) => {
        //@ts-ignore
        acc[key] = input[value];
        return acc;
      },
      {} as { [K in keyof M]: T[M[K]] },
    );
  }
}

const APPROXIMATELY_PROCESS_DURATION = 50; // minutes
const APPROXIMATELY_EXPIRED_PROCESS_DURATION_IN = 60; // minutes
export const calcTradingProgress = <T extends { entryDate: DateValue }>(data: T | null) => {
  if (!data) {
    return { progress: 0, isExpired: false };
  }

  const startDate = convertToDate(data.entryDate);
  const endDate = addMinutes(startDate, APPROXIMATELY_PROCESS_DURATION);

  const approximatelyTotal = differenceInMinutes(endDate, startDate);
  const realDiff = differenceInMinutes(new Date(), startDate);

  const value = interpolation({ y0: 0, y1: 100, x0: 0, x: realDiff, x1: approximatelyTotal });

  return { progress: value, isExpired: realDiff >= APPROXIMATELY_EXPIRED_PROCESS_DURATION_IN };
};

const ratingOrderData = [
  'Aaa',
  'Aaa.il',
  'Aa',
  'Aa.il',
  'Aa1',
  'Aa1.il',
  'Aa2',
  'Aa2.il',
  'Aa3',
  'Aa3.il',
  'A',
  'A.il',
  'A1',
  'A1.il',
  'A2',
  'A2.il',
  'A3',
  'A3.il',
  'Baa',
  'Baa.il',
  'Baa1',
  'Baa1.il',
  'Baa2',
  'Baa2.il',
  'Baa3',
  'Baa3.il',
  'Ba',
  'Ba.il',
  'Ba1',
  'Ba1.il',
  'Ba2',
  'Ba2.il',
  'Ba3',
  'Ba3.il',
  'B',
  'B.il',
  'B1',
  'B1.il',
  'B2',
  'B2.il',
  'B3',
  'B3.il',
  'Caa',
  'Caa.il',
  'Caa1',
  'Caa1.il',
  'Caa2',
  'Caa2.il',
  'Caa3',
  'Caa3.il',
  'Ca',
  'Ca.il',
  'C',
  'C.il',
];

export const sortRatings = (a: string, b: string) => {
  const indexA = ratingOrderData.indexOf(a);
  const indexB = ratingOrderData.indexOf(b);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.localeCompare(b);
};

const groupOrderData = ['Aaa', 'Aa', 'A', 'Baa', 'Ba', 'B', 'Caa', 'Ca', 'C'];

export const sortGroups = (a: string, b: string) => {
  const indexA = groupOrderData.indexOf(a);
  const indexB = groupOrderData.indexOf(b);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.localeCompare(b);
};
