import { APP_ROUTES } from 'configs';
import Logo from 'images/logo.svg';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';

interface Props {
  pageTitle: string;
}

export const Header = memo<Props>(({ pageTitle = '' }) => {
  return (
    <header className={style.root}>
      <Link to={APP_ROUTES.DASHBOARD().config} title={pageTitle} className={style.logo}>
        <img src={Logo} alt={pageTitle} />
      </Link>
    </header>
  );
});
