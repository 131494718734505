import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILinkAssetType } from '__generated__/api';
import { IBondGelemReport, SELECT_BOND_GELEM_REPORT } from 'services/bonds-helper';
import { dateFormat } from 'utils/dates';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import { selectorsReportYieldLinked } from './selectors';

export const actionReportYieldLinkedFetch = createAsyncThunk<
  Array<IBondGelemReport>,
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/actionReportYieldLinkedFetch`, async (_, { getState }) => {
  try {
    const { date } = selectorsReportYieldLinked.filters(getState());
    const { data } = await API.api.bondsHelperGetGelemBondItemsDynamicCreate({
      select: SELECT_BOND_GELEM_REPORT,
      date: dateFormat(date, 'yyyy-MM-dd'),
      linkAssetType: ILinkAssetType.CPI,
    });

    return data.items as unknown as Array<IBondGelemReport>;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
