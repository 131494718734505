import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import {
  IUserProfile,
  IUserProfileDynamicResponse,
  IUserProfilePermission,
} from '__generated__/api';

export const schemaUserProfile = yup.object({
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length').default('').trim(),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length').default('').trim(),
  email: yup.string().nullable().required('rule-required').email('rule-email').default('').trim(),
  userProfilePermissionID: yup.string().nullable().required('rule-required').default(''),
  languageID: yup.string().nullable().required('rule-required').default(''),
  isActive: yup.boolean().required('rule-required').default(true),
  isSystemAlerts: yup.boolean().required('rule-required').default(true),
  userPhoto: yup.mixed().nullable().default(''),
});

export interface IGridUsers
  extends Pick<
    IUserProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'userPhoto'
    | 'fullName'
    | 'userProfilePermissionID'
    | 'email'
    | 'isActive'
  > {
  position: Pick<IUserProfilePermission, 'title'>;
}

export interface IGridProfileArg {
  search: string;
  userProfilePermissionID: string;
  isActive: boolean | null;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}

export interface IUserProfileInfo extends Pick<IUserProfile, 'id' | 'fullName' | 'userPhoto'> {
  createdDate: string;
}

export interface IGridProfileResponse extends Omit<IUserProfileDynamicResponse, 'items'> {
  items: IGridUsers[];
}
