import { addDays, format } from 'date-fns';
import { convertToDate, isDateLike } from 'utils/dates';
import { Paths } from 'utils/types';

class MergeFilters {
  private value: Array<string | undefined>;
  constructor(value: Array<string | undefined>) {
    this.value = value.filter(Boolean);
  }
  join(separator: string) {
    if (this.value.length === 0) {
      return undefined;
    }
    return createStart(this.value.length, this.value.join(separator));
  }
}

type ValueCreator = (name: string, value: any | any[]) => string | undefined;

export const select = <T extends Record<string, any> = Record<string, any>>(
  ...args: (Paths<T> | string)[]
) => {
  return args
    .join(',')
    .replace(/  +/g, ' ') // remove extra spaces
    .replace(/\n/gm, '') // remove new lines
    .replace(' .', '.'); // remove spaces for methods
};

export interface DynamicOrder<F extends string = string> {
  field: F;
  order: 'desc' | 'asc' | null;
}

function _orderBy(order: DynamicOrder): string | undefined;
function _orderBy(name: string, order?: DynamicOrder['order']): string | undefined;
function _orderBy(name: any, order?: any): string | undefined {
  let field = '';
  let fieldOrder = '';
  if (name && typeof name === 'object') {
    field = name.field;
    fieldOrder = name.order;
  } else {
    field = name;
    fieldOrder = order;
  }

  if (!fieldOrder) {
    return undefined;
  }

  const names = field.split(',').filter(Boolean);

  if (names.length === 0) {
    return undefined;
  }

  return names.map((name) => [name, fieldOrder].join(' ')).join(',');
}

export const orderBy = _orderBy;

const escapeSpecialChars = (str: string) => {
  return str.replace(/([.*+?^${}()|[\]\\'"`])/g, '\\$1');
};
const prepareValue = (value?: string) => {
  return escapeSpecialChars(String(value).trim());
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query === '' ? undefined : query;
};

export const makeFilter = <T extends Record<string, any>>(
  name: Paths<T> | Paths<T>[],
  value: any | any[],
  valueCreator: ValueCreator,
): string | undefined => {
  const names = Array.isArray(name) ? name : [name];

  if (value === undefined) {
    return undefined;
  }

  const values = names.map((_n) => valueCreator(String(_n), value)).filter(Boolean);

  return createStart(values.length, values.join('||'));
};

export const dynamicNamespace = <T extends Record<string, any>>() => {
  return {
    select: (...args: Paths<T>[]) => select<T>(...args),
    makeFilter: (name: Paths<T> | Paths<T>[], value: any | any[], valueCreator: ValueCreator) =>
      makeFilter<T>(name, value, valueCreator),
    orderBy: orderBy,
  };
};
export const mergeFilters = (...filters: (string | undefined)[]) => {
  return new MergeFilters(filters);
};

// Value creators
export const more: ValueCreator = (name, value) => {
  return `${name}>${value}`;
};
export const moreOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}>=${value}`;
};
export const less: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<${value}`;
};
export const lessOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<=${value}`;
};
export const equals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}==${innerValue}`;
};
export const notEquals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}!=${innerValue}`;
};
export const contains: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}.toLower().contains("${prepareValue(String(value).toLowerCase())}")`;
};

export const equalsSome: ValueCreator = (name, value: string[]) => {
  if (!Array.isArray(value)) return undefined;
  let values = value.filter(Boolean);
  if (values.length === 0) return undefined;

  let valueStr = values.map((val) => `"${val}"`).join(',');
  return `(new[]{${valueStr}}).Contains(${name})`;
};
export const dateMore: ValueCreator = (name, value) => {
  return `(${name} > DateTime(${format(convertToDate(value), 'yyyy,MM,dd')}))`;
};
export const dateTimeMore: ValueCreator = (name, value) => {
  return `(${name} > DateTime(${format(convertToDate(value), 'yyyy,MM,dd,hh,mm,ss')}))`;
};
export const dateMoreOrEquals: ValueCreator = (name, value) => {
  return `(${name} >= DateTime(${format(convertToDate(value), 'yyyy,MM,dd,hh,mm,ss')}))`;
};
export const dateLess: ValueCreator = (name, value) => {
  return `(${name} < DateTime(${format(convertToDate(value), 'yyyy,MM,dd,hh,mm,ss')}))`;
};
export const dateLessOrEquals: ValueCreator = (name, value) => {
  return `(${name} < DateTime(${format(convertToDate(value), 'yyyy,MM,dd,hh,mm,ss')}))`;
};

export const dateRange: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range = value.filter(isDateLike);

  if (range.length < 2) {
    return undefined;
  }
  const [start, end] = range;
  return [
    `(${name} >= DateTime(${format(addDays(convertToDate(start), -1), 'yyyy,MM,dd,00,00,00')})`,
    `${name} <= DateTime(${format(convertToDate(end), 'yyyy,MM,dd,23,59,59')}))`,
  ].join('&&');
};
export const dateRangeDaily: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range: any[] = value.filter(isDateLike);

  if (range.length < 2) {
    return undefined;
  }
  const [start, end] = range;
  return [
    `(${name} >= "${format(addDays(convertToDate(start), -1), 'yyyy-MM-dd')}"`,
    `${name} <= "${format(convertToDate(end), 'yyyy-MM-dd')}")`,
  ].join('&&');
};

export const dateRangeISO: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range = value.filter(isDateLike);

  if (range.length !== 2) {
    return undefined;
  }

  const [start, end] = range;
  return [
    `(${name} >= DateTime("${convertToDate(start).toISOString()}")`,
    `${name} <= DateTime("${convertToDate(end).toISOString()}"))`,
  ].join('&&');
};

export const minMax: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  if (value.length < 2) {
    return undefined;
  }

  const [min, max] = value;

  const queryArray = [
    min !== null && min !== '' && min !== undefined && `${name} >= ${min}`,
    max !== null && max !== '' && max !== undefined && `${name} <= ${max}`,
  ].filter(Boolean);

  return createStart(queryArray.length, queryArray.join('&&'));
};
// decorators
export const decoratorIsNotNullable = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([value === null, value === ''].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return result;
};
export const decoratorIsNumber = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([!isFinite(value as any)].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorStringify = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    return creator(name, value ? String(value) : value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorValueArray = (creator: ValueCreator, separator = '||') => {
  return ((name, value) => {
    if (!Array.isArray(value)) return undefined;
    if (value.length === 0) return undefined;
    return createStart(
      value.length,
      value.map((v) => makeFilter(name, v, creator)).join(separator),
    );
  }) as ValueCreator;
};
export const decoratorExclude = (creator: ValueCreator, excludeValue: any) => {
  return ((name, value) => {
    if (value === excludeValue) return undefined;
    return creator(name, value);
  }) as ValueCreator;
};
