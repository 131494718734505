import { all, put, take, takeEvery, select } from 'typed-redux-saga';
import { actionProfileStocksCustomFetch, actionProfileStocksSystemFetch } from './actions';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionsProfileStocks } from './slice';
import { apiStocksHelper } from 'services/stocks-helper';
import { selectProfileStocksInit } from './selectors';
import { selectAccountUser } from '../auth';
import { apiUserProfileStocks } from 'services/user-profile-stocks';

function* workerUpdateCustom() {
  const isInit = yield* select(selectProfileStocksInit);
  if (!isInit) return;

  const user = yield* select(selectAccountUser);
  if (!user) return;

  yield* put(actionProfileStocksCustomFetch({ userProfileID: String(user.appUserID) }));
}

function* sagaMakeInit() {
  while (true) {
    yield* all([
      take(actionProfileStocksSystemFetch.pending),
      take(actionProfileStocksCustomFetch.pending),
    ]);

    yield* put(actionsProfileStocks.setInit());
  }
}

export const sagasProfileStocks = [
  sagaMakeInit(),
  takeEvery(actionProfileStocksSystemFetch.rejected, workerErrorNotifyThunk),
  takeEvery(actionProfileStocksCustomFetch.rejected, workerErrorNotifyThunk),
  takeEvery(
    [
      apiUserProfileStocks.endpoints.patchUserProfileStockAlert.matchFulfilled,
      apiStocksHelper.endpoints.updateIsActivePortfolioStocks.matchFulfilled,
      apiStocksHelper.endpoints.stocksResetToUserDefault.matchFulfilled,
      apiStocksHelper.endpoints.stocksResetToSystemDefault.matchFulfilled,

      apiStocksHelper.endpoints.stocksLoadUserStocksAlertsBySymbols.matchFulfilled,
      apiStocksHelper.endpoints.stocksLoadUserStocksAlertsBySymbols.matchRejected,

      apiStocksHelper.endpoints.stocksLoadUserStocksAlertsByIssuers.matchFulfilled,
      apiStocksHelper.endpoints.stocksLoadUserStocksAlertsByIssuers.matchRejected,

      apiStocksHelper.endpoints.stocksDeleteUserBondsAlerts.matchFulfilled,
      apiStocksHelper.endpoints.stocksInsertNewUserStocksAlerts.matchFulfilled,
    ],

    workerUpdateCustom,
  ),
];
