import { APP_ROUTES } from 'configs';
import { useAppSelector } from 'hooks/redux';
import { useRedirectPath } from 'hooks/use-redirect-path';
import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { selectAccountUser } from 'store/auth';

interface Props {
  children: React.ReactNode;
}
export const RequireAuth = memo<Props>(({ children }) => {
  const user = useAppSelector(selectAccountUser);

  const redirect = useRedirectPath();

  if (!user) {
    return <Navigate to={APP_ROUTES.LOGIN({ redirect }).config} replace={true} />;
  }
  return <>{children}</>;
});
