import { IUserProfile } from '__generated__/api';
import { AxiosResponse } from 'axios';
import { patchFiles, postFiles } from 'modules/dynamic-service';
import * as dynamic from 'utils/dynamic';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';
import { makeUniqueFileName } from 'utils/file-uploader';
import { omit } from 'utils/other';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IGridProfileArg, IGridProfileResponse, IUserProfileInfo } from './models';

export * from './models';

const requestGet = API.api.userProfilesGetAllDynamicList;
const requestPost = API.api.userProfilesCreateCreate;
const requestPatch = API.api.userProfilesPatchPartialUpdate;
const requestDelete = API.api.userProfilesDeleteDelete;

const REVALIDATE_KEY = RTK_TAGS.USERS;

export const apiUserProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserProfileInfo: build.query<IUserProfileInfo, string>({
      queryFn: async (appIdentityUserID: string) => {
        try {
          const res = await requestGet({
            Select: dynamic.select('id', 'fullName', 'userPhoto', 'createdDate'),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'appIdentityUserID',
                  appIdentityUserID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem<IUserProfileInfo>(res as unknown as any);
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),

    getUserProfileSource: build.query<Pick<IUserProfile, 'id' | 'fullName'>[], void>({
      queryFn: async () => {
        try {
          const response = await requestGet({
            Select: dynamic.select('appIdentityUserID as id', 'fullName'),
            Filter: dynamic
              .mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals))
              .join('&&'),
            OrderBy: 'isActive desc, firstName,lastName asc',
          });

          return rtkAdapterDynamicToSource(response);
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
    }),
    getGridUserProfiles: build.query<IGridProfileResponse, IGridProfileArg>({
      queryFn: async ({
        search,
        isActive,
        take: Take,
        skip: Skip,
        orderBy,
        userProfilePermissionID,
      }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'fullName',
              'userPhoto',
              'appIdentityUserID',
              'email',
              'isActive',
              'userProfilePermissionID',
              'userProfilePermission.title as position',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['firstName', 'lastName'], search, dynamic.contains),
                dynamic.makeFilter(
                  'userProfilePermissionID',
                  userProfilePermissionID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            OrderBy: orderBy.field
              ? dynamic.orderBy(orderBy.field, orderBy.order)
              : 'isActive desc, firstName,lastName asc',
            Take,
            Skip,
            Count: true,
          });

          return res as AxiosResponse<IGridProfileResponse>;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getUserProfile: build.query({
      queryFn: async (appIdentityUserID: string) => {
        try {
          const res = await requestGet({
            Take: 1,
            Filter: dynamic.makeFilter('appIdentityUserID', appIdentityUserID, dynamic.equals),
          });
          return rtkAdapterDynamicItem(res);
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postUserProfile: build.mutation({
      queryFn: async (input: Omit<IUserProfile, 'appIdentityUserID'>) => {
        const [postData, transaction] = await postFiles(input, {
          userPhoto: makeUniqueFileName('userPhoto'),
        });

        try {
          const result = await requestPost(postData);
          return result;
        } catch (e: unknown) {
          transaction();
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchUserProfile: build.mutation({
      queryFn: async ({ dataNew, dataOld }: { dataNew: IUserProfile; dataOld?: IUserProfile }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          userPhoto: makeUniqueFileName('userPhoto'),
        });

        try {
          return requestPatch(...makePatchArgs(omit(patchData, 'email'), 'appIdentityUserID'));
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    deleteUserProfile: build.mutation<
      IUserProfile,
      PatchPartial<IUserProfile, 'appIdentityUserID'>
    >({
      queryFn: async (data) => {
        try {
          const res = await requestDelete(String(data?.id));
          return res;
        } catch (e: unknown) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),

    setUserDefaultSystemAlerts: build.mutation<
      void,
      { appIdentityUserID: string; isSystemAlerts: boolean }
    >({
      queryFn: async (input) => {
        try {
          await requestPatch(...makePatchArgs(input, 'appIdentityUserID'));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e);
        }
      },
      onQueryStarted: async (input, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(
            apiUserProfiles.util.updateQueryData(
              'getUserProfile',
              input.appIdentityUserID,
              (draft) => {
                Object.assign(draft, { isSystemAlerts: input.isSystemAlerts });
              },
            ),
          );
        } catch {}
      },
    }),
  }),
});
