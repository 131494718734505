import { AppLoading } from 'components/app-loading';
import { PageLoader } from 'components/page-loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { memo, useEffect } from 'react';
import { actionAccountGetUser, selectAccountState } from 'store/auth';

interface Props {
  children: React.ReactNode;
}
export const AuthProvider = memo<Props>(({ children }) => {
  const { isInit, isLoading } = useAppSelector(selectAccountState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isInit) {
      dispatch(actionAccountGetUser({ isOnTheBackground: false }));
    }
  }, [isInit, dispatch]);

  return (
    <>
      {isInit && children}
      {isLoading && (isInit ? <AppLoading /> : <PageLoader />)}
    </>
  );
});
