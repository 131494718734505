import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, memo, useMemo } from 'react';
import style from './index.module.scss';

export interface NativeScrollProps extends BoxProps {
  className?: string;
  mode?: 'hover' | 'always';
  size?: 'small' | 'medium';
}

const Component = (
  { className, children, onScroll, mode = 'hover', size = 'medium', ...rest }: NativeScrollProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const trackVisibilityClass = useMemo(() => {
    switch (mode) {
      case 'always':
        return style.all;
      default:
        return null;
    }
  }, [mode]);
  const sizeCLass = useMemo(() => {
    switch (size) {
      case 'small':
        return style.small;
      default:
        return null;
    }
  }, [size]);
  return (
    <Box
      ref={ref}
      height={'100%'}
      overflow={'auto'}
      position={'relative'}
      className={clsx(style.root, className, trackVisibilityClass, sizeCLass)}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const NativeScroll = memo(forwardRef(Component)) as typeof Component;
