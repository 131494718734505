import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { GetGridStocksForPortfolioItem } from 'services/stock';
import { GetUserProfileStocksForProfileItem } from 'services/user-profile-stocks';
import { selectItemsAll, toggleItem } from 'utils/other';
import {
  actionProfileStocksCustomFetch,
  actionProfileStocksCustomRemove,
  actionProfileStocksSystemAttach,
  actionProfileStocksSystemDetach,
  actionProfileStocksSystemFetch,
} from './actions';
import { PREFIX } from './helpers';

interface SystemFilters {
  stocks: string[];
  issuerIDs: string[];

  equityTypeIDs: string[];
  branchIDs: string[];
}

interface CustomFilters {
  stocks: string[];
  issuerIDs: string[];
}

interface BaseSubState<T, F> {
  isLoading: boolean;
  isSuccess: boolean;
  data: Array<T & { id: string; __isSelected: boolean }>;
  filters: F;
}

interface InitState {
  isInit: boolean;
  system: BaseSubState<GetGridStocksForPortfolioItem, SystemFilters>;
  custom: BaseSubState<GetUserProfileStocksForProfileItem, CustomFilters>;
}

export const initState = (): InitState => {
  return {
    isInit: false,
    system: {
      isLoading: false,
      isSuccess: false,
      data: [],
      filters: {
        stocks: [],
        issuerIDs: [],
        equityTypeIDs: [],
        branchIDs: [],
      },
    },
    custom: {
      isLoading: false,
      isSuccess: false,
      data: [],
      filters: {
        stocks: [],
        issuerIDs: [],
      },
    },
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    selectCustomAll(state, action: PayloadAction<{ value: boolean }>) {
      selectItemsAll(state.custom.data, action.payload);
    },
    toggleCustom(state, action: PayloadAction<{ id: string }>) {
      toggleItem(state.custom.data, action.payload.id);
    },
    selectSystemAll(state, action: PayloadAction<{ value: boolean }>) {
      selectItemsAll(state.system.data, action.payload);
    },
    toggleSystem(state, action: PayloadAction<{ id: string }>) {
      toggleItem(state.system.data, action.payload.id);
    },
    setFiltersCustom(state, action: PayloadAction<CustomFilters>) {
      state.custom.filters = action.payload;
    },
    resetFiltersCustom(state) {
      state.custom.filters = initState().custom.filters;
    },
    setFiltersSystem(state, action: PayloadAction<SystemFilters>) {
      state.system.filters = action.payload;
    },
    resetFiltersSystem(state) {
      state.system.filters = initState().system.filters;
    },
    setInit(state) {
      state.isInit = true;
    },
    reset() {
      return initState();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionProfileStocksSystemFetch.pending, (state) => {
        state.system.isLoading = true;
      })
      .addCase(actionProfileStocksSystemFetch.fulfilled, (state, action) => {
        state.system.isLoading = false;
        state.system.data = action.payload.map((item) => {
          return { ...item, __isSelected: false };
        });
      })
      .addCase(actionProfileStocksSystemFetch.rejected, (state) => {
        state.system.isLoading = false;
      });

    builder
      .addCase(actionProfileStocksCustomFetch.pending, (state) => {
        state.custom.isLoading = true;
      })
      .addCase(actionProfileStocksCustomFetch.fulfilled, (state, action) => {
        state.custom.isLoading = false;
        state.custom.isSuccess = true;
        state.custom.data = action.payload.map((item) => {
          return { ...item, __isSelected: false };
        });
      })
      .addCase(actionProfileStocksCustomFetch.rejected, (state) => {
        state.custom.isLoading = false;
      });

    builder
      .addCase(actionProfileStocksCustomRemove.pending, (state) => {
        state.custom.isLoading = true;
      })
      .addCase(actionProfileStocksCustomRemove.fulfilled, (state) => {
        state.custom.isLoading = false;
      })
      .addCase(actionProfileStocksCustomRemove.rejected, (state) => {
        state.custom.isLoading = false;
      });

    builder
      .addMatcher(
        isAnyOf(
          actionProfileStocksSystemAttach.pending.match,
          actionProfileStocksSystemDetach.pending.match,
        ),
        (state) => {
          state.system.isLoading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          actionProfileStocksSystemAttach.fulfilled.match,
          actionProfileStocksSystemDetach.fulfilled.match,
        ),
        (state) => {
          state.system.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          actionProfileStocksSystemAttach.rejected.match,
          actionProfileStocksSystemDetach.rejected.match,
        ),
        (state) => {
          state.system.isLoading = false;
        },
      );
  },
});
export const actionsProfileStocks = slice.actions;
export const reducerProfileStocks = slice.reducer;
