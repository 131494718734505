import React from 'react';
import { Menu, MenuOpen } from '@mui/icons-material';
import { Box, IconButton, Stack, StackProps } from '@mui/material';
import clsx from 'clsx';
import Logo from 'images/logo.svg?react';
import style from './index.module.scss';

interface Props extends Omit<StackProps, 'classes'> {
  isOpen: boolean;
  onToggle: () => void;
}

export const MenuLogo: React.FC<Props> = ({ isOpen, onToggle, children, className, ...rest }) => {
  return (
    <Stack direction={'row'} {...rest} className={clsx(style.root)}>
      <Box className={clsx(style.icon)}>
        <IconButton color={isOpen ? 'primary' : 'inherit'} onClick={onToggle}>
          {isOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
      </Box>
      <Stack
        direction={'row'}
        alignSelf={'center'}
        justifyContent={'center'}
        ml={'-2rem'}
        flex={'0 0 18rem'}
        className={clsx(style.box, isOpen && style.boxOpen)}
      >
        <Logo style={{ width: '12rem', flex: '0 0 12rem' }} />
      </Stack>
    </Stack>
  );
};
